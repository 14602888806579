import {
  Button,
  Divider,
  Form,
  Input,
  Radio,
  Select,
  Upload,
  UploadProps,
  Card,
  Breadcrumb,
} from "antd";
import Header from "../../../components/common/Header";
import useNotification from "antd/es/notification/useNotification";

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HContent } from "../../../components/common/HContent";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";

import { UploadFile } from "antd/lib/upload/interface";
import { findCategoryS3Update } from "../../../utils/data";
import { InboxOutlined } from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import { handleUploadCompMultiFilesV2 } from "../../../utils/uploadImgToS3";
import { CategoryFromApiType } from "../../../types/CategoryFromApiType";

const { Dragger } = Upload;
export default function CreatePage() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = useNotification();
  const [form] = Form.useForm();

  const [artwork, setArtwork] = useState<UploadFile[]>([]);
  const [thumbnail, setThumbnail] = useState<UploadFile[]>([]);
  const [musicFile, setMusicFile] = useState<UploadFile[]>([]);
  const [duration, setDuration] = useState(0);
  const [category, setCategory] = useState<
    {
      label: CategoryFromApiType["categoryKo"];
      value: CategoryFromApiType["id"];
      legacyId: CategoryFromApiType["legacyId"];
    }[]
  >([]);

  const handleUpdateTrack = async (formValue: any) => {
    setLoading(true);
    try {
      const legacyIdOfCategory = category.find(
        (c) => c.value === formValue.categoryId
      )?.legacyId;

      const artworkImage =
        (await handleUploadCompMultiFilesV2(
          artwork,
          "image",
          findCategoryS3Update(legacyIdOfCategory)
        )) ?? [];
      const thumbnailImage =
        (await handleUploadCompMultiFilesV2(
          thumbnail,
          "thumbnail",
          findCategoryS3Update(legacyIdOfCategory)
        )) ?? [];
      const soundSrc =
        (await handleUploadCompMultiFilesV2(
          musicFile,
          "audio",
          findCategoryS3Update(legacyIdOfCategory)
        )) ?? [];

      const result = {
        ...formValue,
        artwork: artworkImage[0],
        thumbnail: thumbnailImage[0],
        url: soundSrc[0],
        tagKo: formValue.tagKo.join(","),
        tagEn: formValue.tagEn.join(","),

        duration: duration,
      };

      await LPPAXIOS.post(`/admin/track`, result);
      api.success({
        message: "사운드 생성이 완료되었습니다.",
      });
      setTimeout(() => {
        navigate(LPPUrls.Admin.Sounds.Main.url(), { replace: true });
      }, 500);
    } catch (error) {
      api.error({
        message: "사운드 생성이 실패하였습니다.",
      });
    } finally {
      setLoading(false);
    }
  };

  const onChangeArtWork: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    const newUpdatedFileList = newFileList.map((file) => {
      if (file.status !== "done") {
        file.status = "done";
      }
      return file;
    });
    setArtwork(newUpdatedFileList);
  };
  const onChangeThumbNail: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    const newUpdatedFileList = newFileList.map((file) => {
      if (file.status !== "done") {
        file.status = "done";
      }
      return file;
    });
    setThumbnail(newUpdatedFileList);
  };

  const onChangeMusicFile: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    const newUpdatedFileList = newFileList.map((file) => {
      if (file.status !== "done") {
        // 새로운 파일을 위한 메타데이터 처리
        if (file.originFileObj) {
          const audio = new Audio();
          audio.src = URL.createObjectURL(file.originFileObj);
          audio.onloadedmetadata = () => {
            // 파일 리스트를 업데이트하기 전에 파일의 duration을 가져옵니다.
            const duration = audio.duration;
            setDuration(Math.trunc(duration));
            // 필요하다면, 여기서 파일의 메타데이터 정보를 파일 객체에 추가할 수 있습니다.
            // 예를 들어, 아래와 같이 할 수 있습니다.
            // file.duration = duration;

            // 메모리 누수를 방지하기 위해 URL을 정리합니다.
            URL.revokeObjectURL(audio.src);

            // 이제 파일 상태를 'done'으로 설정합니다.
            file.status = "done";

            // 상태 업데이트
          };
        }
      }
      return file;
    });
    setMusicFile(newUpdatedFileList);
  };

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get(`/admin/category`)
      .then((res) => {
        const categoryFromApi = res.data.list.map(
          (category: CategoryFromApiType) => ({
            label: category.categoryKo,
            value: category.id,
            legacyId: category.legacyId,
          })
        );

        setCategory([
          ...categoryFromApi,
          { label: "카테고리 없음", value: "none" },
        ]);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });

    form.setFieldsValue({
      categoryId: "none",
      isFree: false,
    });
  }, []);
  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm">
        <Title level={4}>사운드 생성</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Sounds.Main.url()}>사운드</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>사운드 생성</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Form form={form} onFinish={handleUpdateTrack}>
          <Title level={4}>언어</Title>

          <Card title="한국어">
            <Form.Item name="titleKo" label="제목">
              <Input />
            </Form.Item>
            <Form.Item name="descriptionKo" label="설명">
              <Input />
            </Form.Item>
            <Form.Item name="tagKo" label="태그">
              <Select mode="tags" />
            </Form.Item>
          </Card>

          <Card title="영어">
            <Form.Item name="titleEn" label="제목">
              <Input />
            </Form.Item>
            <Form.Item name="descriptionEn" label="설명">
              <Input />
            </Form.Item>
            <Form.Item name="tagEn" label="태그">
              <Select mode="tags" />
            </Form.Item>
          </Card>

          <Divider />
          <Form.Item name="categoryId" label="카테고리">
            <Select options={category} />
          </Form.Item>
          <Form.Item name="isFree" label="구독음원 설정">
            <Radio.Group>
              <Radio value={true}>무료음원</Radio>
              <Radio value={false}>구독음원</Radio>
            </Radio.Group>
          </Form.Item>
          <Title level={4}>미디어 파일</Title>
          <Form.Item
            name="artwork"
            label="고화질 이미지"
            extra="고화질 이미지 등록"
            rules={[
              {
                validator(rule, value, callback) {
                  if (artwork.length > 0) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject();
                  }
                },
                message: "고화질 이미지 등록은 필수입니다.",
              },
            ]}
          >
            <Upload
              fileList={artwork}
              name="artwork"
              multiple={false}
              maxCount={1}
              listType="picture"
              className="upload-list-inline"
              showUploadList={{ showPreviewIcon: true }}
              customRequest={({ file, onSuccess }) => {
                const res = "Ok";
                onSuccess!(res);
              }}
              accept=".jpg, .jpeg, .png, .webp"
              onChange={onChangeArtWork}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="thumbnail"
            label="저화질 이미지"
            extra="저화질 이미지 등록"
            rules={[
              {
                validator(rule, value, callback) {
                  if (thumbnail.length > 0) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject();
                  }
                },
                message: "썸네일 이미지 등록은 필수입니다.",
              },
            ]}
          >
            <Upload
              fileList={thumbnail}
              name="thumbnail"
              multiple={false}
              maxCount={1}
              listType="picture"
              className="upload-list-inline"
              showUploadList={{ showPreviewIcon: true }}
              customRequest={({ file, onSuccess }) => {
                const res = "Ok";
                onSuccess!(res);
              }}
              accept=".jpg, .jpeg, .png, .webp"
              onChange={onChangeThumbNail}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="url"
            label="음원 파일"
            rules={[
              {
                validator(rule, value, callback) {
                  if (musicFile.length > 0) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject();
                  }
                },
                message: "음원소스 등록은 필수입니다.",
              },
            ]}
          >
            <Dragger
              fileList={musicFile}
              name="file"
              accept="audio/mp3"
              maxCount={1}
              multiple={false}
              onChange={onChangeMusicFile}
              beforeUpload={() => false}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                해당 영역에 클릭 또는 드래그하여 파일을 업로드합니다.
              </p>
              <p className="ant-upload-hint">
                음원을 제외한 파일업로드를 제한합니다.
              </p>
            </Dragger>
          </Form.Item>
          <Button block htmlType="submit" type="primary">
            사운드 생성
          </Button>
        </Form>
      </HContent>
    </>
  );
}
