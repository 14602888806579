import { Route, Routes } from "react-router-dom";
import ClinicalTrialUserTable from "./Table";

export default function ClinicalTrialUserRoot() {
  return (
    <Routes>
      <Route path="/" element={<ClinicalTrialUserTable/>} />
    </Routes>
  );
}
