import {
  Button,
  Divider,
  Form,
  Input,
  Select,
  Upload,
  UploadProps,
  Card,
  Breadcrumb,
  UploadFile,
} from "antd";
import useNotification from "antd/es/notification/useNotification";
import { useLayoutEffect, useState } from "react";
import {
  ClinicalTrialTrackCategoryEnum,
  ClinicalTrialTypeFormResult,
} from "../../pages/AdminPages/ClinicalTrial/Track/type";
import Header from "../common/Header";
import { LoadingSpin } from "../common/Loading";
import Title from "antd/es/typography/Title";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LPPUrls } from "../../LPPUrls";
import { HContent } from "../common/HContent";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { handleUploadCompMultiFilesV2 } from "../../utils/uploadImgToS3";
import { LPPAXIOS } from "../../framework/api/core.api";

type PopupFormProps = {
  isCreateForm: boolean;
  onSubmit: (form: ClinicalTrialTypeFormResult) => Promise<Boolean>;
};
const { Dragger } = Upload;
export default function ClinicalTrialTrackForm({
  isCreateForm,
  onSubmit,
}: PopupFormProps) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = useNotification();
  const [artwork, setArtwork] = useState<UploadFile[]>([]);
  const [thumbnail, setThumbnail] = useState<UploadFile[]>([]);
  const [musicFile, setMusicFile] = useState<UploadFile[]>([]);
  const [duration, setDuration] = useState(0);
  const { id } = useParams();
  const formModeLabel = isCreateForm ? "생성" : "수정";

  useLayoutEffect(() => {
    if (!isCreateForm) {
      setLoading(true);
      const getUpdateData = async () => {
        const apiResult = await LPPAXIOS.get(
          `/admin/clinical-trial/track/${id}`
        );
        const prevData: ClinicalTrialTypeFormResult = apiResult.data;
        startEdit([prevData.artwork], setArtwork);
        startEdit([prevData.thumbnail], setThumbnail);
        startEdit([prevData.url], setMusicFile);
        setDuration(prevData.duration);
        const tagKo = prevData.tagKo.split(",");
        const tagEn = prevData.tagEn.split(",");
        form.setFieldsValue({
          ...prevData,
          tagKo,
          tagEn,
        });
        console.log("getUpdateData ~ prevData:", prevData);
        setLoading(false);
      };
      getUpdateData();
    }
  }, [id, isCreateForm]);

  const startEdit = (imageList: string[], stateFunc: Function) => {
    const imageFileList = imageList;
    const arr = [];
    for (let i = 0; i < imageFileList.length; i++) {
      const previewFile: UploadFile = {
        uid: `done file ${i}`,
        name: imageFileList[i],
        status: "done",
        url: `${process.env.REACT_APP_IMG_ENDPOINT}/${imageFileList[i]}`,
      };
      arr.push(previewFile);
    }
    stateFunc(arr);
  };

  const onChangeArtWork: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    const newupdatedFileList = newFileList.map((file) => {
      if (file.status !== "done") {
        file.status = "done";
      }
      return file;
    });
    setArtwork(newupdatedFileList);
  };
  const onChangeThumbNail: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    const newupdatedFileList = newFileList.map((file) => {
      if (file.status !== "done") {
        file.status = "done";
      }
      return file;
    });
    setThumbnail(newupdatedFileList);
  };

  const onChangeMusicFile: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    const newupdatedFileList = newFileList.map((file) => {
      if (file.status !== "done") {
        // 새로운 파일을 위한 메타데이터 처리
        if (file.originFileObj) {
          const audio = new Audio();
          audio.src = URL.createObjectURL(file.originFileObj);
          audio.onloadedmetadata = () => {
            // 파일 리스트를 업데이트하기 전에 파일의 duration을 가져옵니다.
            const duration = audio.duration;
            setDuration(Math.trunc(duration));
            console.log(`${file.name} duration: ${Math.trunc(duration)}`);
            // 필요하다면, 여기서 파일의 메타데이터 정보를 파일 객체에 추가할 수 있습니다.
            // 예를 들어, 아래와 같이 할 수 있습니다.
            // file.duration = duration;

            // 메모리 누수를 방지하기 위해 URL을 정리합니다.
            URL.revokeObjectURL(audio.src);

            // 이제 파일 상태를 'done'으로 설정합니다.
            file.status = "done";

            // 상태 업데이트
          };
        }
      }
      return file;
    });
    setMusicFile(newupdatedFileList);
  };

  const onFormConfirm = async (formValue: Record<string, any>) => {
    setLoading(true);
    const { category } = formValue;
    const uploadPath = `clinical-trial/${category}`;
    try {
      let storageUserInfo = localStorage.getItem("app.soundpill.admin.info");
      if (!storageUserInfo) throw new Error("not found storageUserInfo");
      const parseStorageUserInfo = JSON.parse(storageUserInfo);
      const artworkImage =
        (await handleUploadCompMultiFilesV2(artwork, "image", uploadPath)) ??
        [];
      const thumbnailImage =
        (await handleUploadCompMultiFilesV2(
          thumbnail,
          "thumbnail",
          uploadPath
        )) ?? [];
      const soundSrc =
        (await handleUploadCompMultiFilesV2(musicFile, "audio", uploadPath)) ??
        [];

      const formResult: ClinicalTrialTypeFormResult = {
        writer: String(parseStorageUserInfo.id),
        artwork: artworkImage[0],
        thumbnail: thumbnailImage[0],
        url: soundSrc[0],
        category,
        duration: duration,
        tagKo: formValue.tagKo.join(","),
        tagEn: formValue.tagEn.join(","),
        titleKo: formValue.titleKo,
        titleEn: formValue.titleEn,
        descriptionKo: formValue.descriptionKo,
        descriptionEn: formValue.descriptionEn,
      };
      console.log("onFormConfirm ~ formResult:", formResult);

      const apiIsSuccess = await onSubmit(formResult);
      if (apiIsSuccess) {
        setTimeout(() => {
          navigate(LPPUrls.Admin.ClinicalTrialTrack.Main.url(), {
            replace: true,
          });
        }, 500);
      } else {
        throw new Error(`API ${formModeLabel} 실패`);
      }
    } catch (error) {
      api.error({
        message: `사운드 ${formModeLabel}이 실패하였습니다.`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm">
        <Title level={4}>사운드 {formModeLabel}</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.ClinicalTrialTrack.Main.url()}>사운드</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>사운드 {formModeLabel}</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Form form={form} onFinish={onFormConfirm}>
          <Title level={4}>언어</Title>

          <Card title="한국어">
            <Form.Item
              name="titleKo"
              label="제목"
              rules={[
                {
                  required: true,
                  message: "제목을 입력하세요.",
                },
                {
                  max: 1000,
                  message: "최대 1000자까지 작성가능합니다.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="descriptionKo"
              label="설명"
              rules={[
                {
                  required: true,
                  message: "설명을 입력하세요.",
                },
                {
                  max: 5000,
                  message: "최대 5000자까지 작성가능합니다.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="tagKo"
              label="태그"
              rules={[
                {
                  required: true,
                  message: "태그를 입력하세요.",
                },
              ]}
            >
              <Select mode="tags" />
            </Form.Item>
          </Card>

          <Card title="영어" className="mt-5">
            <Form.Item
              name="titleEn"
              label="제목"
              rules={[
                {
                  required: true,
                  message: "제목을 입력하세요.",
                },
                {
                  max: 1000,
                  message: "최대 1000자까지 작성가능합니다.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="descriptionEn"
              label="설명"
              rules={[
                {
                  required: true,
                  message: "설명을 입력하세요.",
                },
                {
                  max: 5000,
                  message: "최대 5000자까지 작성가능합니다.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="tagEn"
              label="태그"
              rules={[
                {
                  required: true,
                  message: "태그를 입력하세요.",
                },
              ]}
            >
              <Select mode="tags" />
            </Form.Item>
          </Card>

          <Divider />
          <Form.Item
            name="category"
            label="카테고리"
            rules={[{ required: true, message: "카테고리를 선택하세요." }]}
          >
            <Select
              options={Object.values(ClinicalTrialTrackCategoryEnum).map(
                (category) => ({
                  label: category,
                  value: category,
                })
              )}
            />
          </Form.Item>
          <Title level={4}>미디어 파일</Title>
          <Form.Item
            name="artwork"
            label="고화질 이미지"
            extra="고화질 이미지 등록"
            rules={[
              {
                validator(rule, value, callback) {
                  if (artwork.length > 0) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject();
                  }
                },
                message: "고화질 이미지 등록은 필수입니다.",
              },
            ]}
          >
            <Upload
              fileList={artwork}
              name="artwork"
              multiple={false}
              maxCount={1}
              listType="picture"
              className="upload-list-inline"
              showUploadList={{ showPreviewIcon: true }}
              // beforeUpload={(file: RcFile) => {
              //   const isLt2M = file.size / 1024 / 1024 < 2;
              //   if (!isLt2M) {
              //     message.error("Image must smaller than 2MB!");
              //   }
              //   return isLt2M;
              // }}
              customRequest={({ file, onSuccess }) => {
                const res = "Ok";
                onSuccess!(res);
              }}
              accept=".jpg, .jpeg, .png, .webp"
              onChange={onChangeArtWork}
            >
              {/* <UploadButton /> */}
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="thumbnail"
            label="저화질 이미지"
            extra="저화질 이미지 등록"
            rules={[
              {
                validator(rule, value, callback) {
                  if (thumbnail.length > 0) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject();
                  }
                },
                message: "썸네일 이미지 등록은 필수입니다.",
              },
            ]}
          >
            <Upload
              fileList={thumbnail}
              name="thumbnail"
              multiple={false}
              maxCount={1}
              listType="picture"
              className="upload-list-inline"
              showUploadList={{ showPreviewIcon: true }}
              // beforeUpload={(file: RcFile) => {
              //   const isLt2M = file.size / 1024 / 1024 < 0.5;
              //   if (!isLt2M) {
              //     message.error("Image must smaller than 0.5MB!");
              //   }
              //   return isLt2M;
              // }}
              customRequest={({ file, onSuccess }) => {
                const res = "Ok";
                onSuccess!(res);
              }}
              accept=".jpg, .jpeg, .png, .webp"
              onChange={onChangeThumbNail}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="url"
            label="음원 파일"
            rules={[
              {
                validator(rule, value, callback) {
                  if (musicFile.length > 0) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject();
                  }
                },
                message: "음원소스 등록은 필수입니다.",
              },
            ]}
          >
            <Dragger
              fileList={musicFile}
              name="file"
              accept="audio/mp3"
              maxCount={1}
              multiple={false}
              onChange={onChangeMusicFile}
              beforeUpload={() => false}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                해당 영역에 클릭 또는 드래그하여 파일을 업로드합니다.
              </p>
              <p className="ant-upload-hint">
                음원을 제외한 파일업로드를 제한합니다.
              </p>
            </Dragger>
          </Form.Item>
          <Button block htmlType="submit" type="primary">
            사운드 {formModeLabel}
          </Button>
        </Form>
      </HContent>
    </>
  );
}
