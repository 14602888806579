import { Header } from "antd/es/layout/layout";
import { UploadOutlined } from "@ant-design/icons";
import React, { useEffect, useLayoutEffect, useState } from "react";
import Title from "antd/es/typography/Title";
import {
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  TimePicker,
  UploadFile,
  message,
} from "antd";
import {
  ImagesStateType,
  PopupFormResult,
  PopupLanguagesEnum,
  PopupPlatformEnum,
  PopupTargetEnum,
  PopupTypeEnum,
} from "../../types/homeNoticePopupTypes";
import { HContent } from "../common/HContent";
import Upload from "antd/es/upload/Upload";
import { RcFile, UploadChangeParam } from "antd/es/upload";
import TextArea from "antd/es/input/TextArea";
import { LoadingSpin } from "../common/Loading";
import useNotification from "antd/es/notification/useNotification";
import { handleUploadCompMultiFilesV2 } from "../../utils/uploadImgToS3";
import { useParams } from "react-router-dom";
import { LPPAXIOS } from "../../framework/api/core.api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

type PopupFormProps = {
  isCreateForm: boolean;
  onSubmit: (form: PopupFormResult) => Promise<void>;
};

export default function HomeNoticePopupForm({
  isCreateForm,
  onSubmit,
}: PopupFormProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = useNotification();
  const [popupType, setPopupType] = useState(PopupTypeEnum.IMAGE); // 현재 팝업 타입 상태 관리
  const { id } = useParams();
  const [images, setImages] = useState<ImagesStateType>({
    [PopupLanguagesEnum.EN]: {
      [PopupPlatformEnum.MOBILE]: [] as UploadFile[],
      [PopupPlatformEnum.PC]: [] as UploadFile[],
    },
    [PopupLanguagesEnum.KO]: {
      [PopupPlatformEnum.MOBILE]: [] as UploadFile[],
      [PopupPlatformEnum.PC]: [] as UploadFile[],
    },
  });

  useLayoutEffect(() => {
    if (!isCreateForm) {
      const getUpdateData = async () => {
        setLoading(true);
        const apiResult = await LPPAXIOS.get(`/admin/home-notice/${id}`);
        const prevData: PopupFormResult = apiResult.data;
        setPopupType(apiResult.data.popupType);
        const startDate = dayjs(prevData.startIsoDate).tz("Asia/Seoul").startOf("day");
        const startTime = dayjs(prevData.startIsoDate).tz("Asia/Seoul");
        const endDate = dayjs(prevData.endIsoDate).tz("Asia/Seoul").startOf("day");
        const endTime = dayjs(prevData.endIsoDate).tz("Asia/Seoul");
        const uploadImageConvertingToUploadFile = [
          "enPcImage",
          "enMobileImage",
          "koPcImage",
          "koMobileImage",
        ];
        uploadImageConvertingToUploadFile.forEach((key) => {

          const urlPath = prevData[key as keyof PopupFormResult];
          delete prevData[key as keyof PopupFormResult];
          if (urlPath) {
            const prevUploadFile: UploadFile = {
              uid: `done file ${urlPath}`,
              name: urlPath,
              status: "done",
              url: `${process.env.REACT_APP_IMG_ENDPOINT}/${urlPath}`,
            };
            const keySplit = key
              .split(/(?=[A-Z])/)
              .map((word) => word.toLowerCase());
            handleFileChange(
              keySplit[0] as PopupLanguagesEnum,
              keySplit[1] as PopupPlatformEnum
            )({ fileList: [prevUploadFile] } as UploadChangeParam);
          }
        });
        form.setFieldsValue({
          ...prevData,
          startDate,
          startTime,
          endDate,
          endTime,
        });
        setLoading(false);
      };
      getUpdateData();
    }
  }, [id, isCreateForm]);

  const handlePopupTypeChange = (e: RadioChangeEvent) => {
    setPopupType(e.target.value);
  };
  const handleFileChange =
    (lang: PopupLanguagesEnum, platform: PopupPlatformEnum) =>
    (info: UploadChangeParam) => {
      const { fileList } = info; // 새로운 파일 리스트

      const loadedFileList = fileList.map((file) => {
        if (file.status !== "done") {
          file.status = "done";
        }
        return file;
      });
      setImages((prevImages) => ({
        ...prevImages,
        [lang]: {
          ...prevImages[lang], // lang에 해당하는 객체는 그대로 두고
          [platform]: loadedFileList, // 해당 platform에 맞는 fileList만 업데이트
        },
      }));
    };


  const onFinishForm = async (form: Record<string, any>) => {
    setLoading(true);

    try {
      // 날짜와 시간을 조합하여 ISO 형식으로 변환
      const {
        startDate,
        startTime,
        endDate,
        endTime,
        popupType,
        exposeTarget,
        enTitle,
        enContent,
        koTitle,
        koContent,
      } = form;
      const uploadedImages: { [key: string]: string } = {
        enPcImage: "",
        enMobileImage: "",
        koPcImage: "",
        koMobileImage: "",
      };

      const startIsoDate = dayjs(
        `${startDate.format("YYYY-MM-DD")}T${startTime.format("HH:mm:ss")}`
      ).toISOString();
      const endIsoDate = dayjs(
        `${endDate.format("YYYY-MM-DD")}T${endTime.format("HH:mm:ss")}`
      ).toISOString();


      if (popupType === PopupTypeEnum.IMAGE) {
        try {
          const imageUploadList = [
            { obj: images.en.pc, outputKey: "enPcImage" },
            { obj: images.en.mobile, outputKey: "enMobileImage" },
            { obj: images.ko.pc, outputKey: "koPcImage" },
            { obj: images.ko.mobile, outputKey: "koMobileImage" },
          ];
          for (let { obj, outputKey } of imageUploadList) {
            try {
              const result = await handleUploadCompMultiFilesV2(
                obj,
                "image",
                "notice"
              );
              if (!result) throw new Error("Some image uploads failed");
              uploadedImages[outputKey] = result[0] as string;
            } catch (error) {
              throw new Error("Some image uploads failed");
            }
          }
        } catch (error) {
          api.error({ message: "이미지 업로드 실패" });
          return;
        }
      }
      let storageUserInfo = localStorage.getItem("app.soundpill.admin.info");
      if (!storageUserInfo) throw new Error("not found storageUserInfo");
      const parseStorageUserInfo = JSON.parse(storageUserInfo);
      const result: PopupFormResult = {
        popupType,
        exposeTarget,
        startIsoDate,
        endIsoDate,
        writer: String(parseStorageUserInfo.id),
        enTitle: enTitle ?? "",
        enContent: enContent ?? "",
        koTitle: koTitle ?? "",
        koContent: koContent ?? "",
        enPcImage: uploadedImages.enPcImage,
        enMobileImage: uploadedImages.enMobileImage,
        koPcImage: uploadedImages.koPcImage,
        koMobileImage: uploadedImages.koMobileImage,
      };
      await onSubmit(result);
    } catch (error) {
      api.error({ message: "HomeNoticePopupFrom 실패" });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <HContent>
        <Form
          form={form}
          onFinish={onFinishForm}
          initialValues={{
            popupType: popupType,
            exposeTarget: PopupTargetEnum.ALL_USERS,
          }}
        >
          <Form.Item
            name="popupType"
            label={
              <div style={{ display: "inline-block", width: "100px" }}>
                팝업 타입 설정
              </div>
            }
            labelAlign="left"
            rules={[
              {
                required: true,
                message: "팝업 타입을 설정하세요.",
              },
            ]}
          >
            <Radio.Group onChange={handlePopupTypeChange}>
              {Object.entries(PopupTypeEnum).map(([key, value]) => (
                <Radio key={key} value={value}>
                  {value}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Divider />
          <Form.Item
            name="exposeTarget"
            label={
              <div style={{ display: "inline-block", width: "100px" }}>
                노출 대상
              </div>
            }
            labelAlign="left"
            rules={[
              {
                required: true,
                message: "노출 대상을 설정하세요.",
              },
            ]}
          >
            <Radio.Group>
              {Object.entries(PopupTargetEnum).map(([key, value]) => (
                <Radio key={key} value={value}>
                  {value}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Divider />
          <div className="font-bold mb-5">
            노출 KST 기준 (DB 저장 데이터 UTC)
          </div>
          <div className="flex flex-row gap-5">
            <Form.Item
              name="startDate"
              label={
                <div style={{ display: "inline-block", width: "100px" }}>
                  노출 시작 날짜
                </div>
              }
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: "노출 시작 날짜를 설정하세요.",
                },
              ]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              name="startTime"
              label={
                <div style={{ display: "inline-block", width: "100px" }}>
                  노출 시작 시간
                </div>
              }
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: "노출 시작 시간을 설정하세요.",
                },
              ]}
            >
              <TimePicker />
            </Form.Item>
          </div>
          <div className="flex flex-row gap-5">
            <Form.Item
              name="endDate"
              label={
                <div style={{ display: "inline-block", width: "100px" }}>
                  노출 종료 날짜
                </div>
              }
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: "노출 종료 날짜를 설정하세요.",
                },
              ]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              name="endTime"
              label={
                <div style={{ display: "inline-block", width: "100px" }}>
                  노출 종료 시간
                </div>
              }
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: "노출 종료 시간을 설정하세요.",
                },
              ]}
            >
              <TimePicker />
            </Form.Item>
          </div>
          <Divider />

          <>
            {Object.entries(PopupLanguagesEnum).map(([langKey, langValue]) => (
              <Card
                key={langKey}
                title={langValue === PopupLanguagesEnum.KO ? "한국어 (PC 1:1 비율, Mobile 9:16 비율)" : "영어 (PC 1:1 비율, Mobile 9:16 비율)"}
                className="mt-5"
              >
                {popupType === PopupTypeEnum.IMAGE && (
                  <>
                    {Object.entries(PopupPlatformEnum).map(
                      ([platformKey, platformValue]) => (
                        <Form.Item
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }} // 왼쪽
                          key={`${langKey}_${platformKey}`}
                          name={`${langValue}_${platformValue}_form`}
                          label={
                            <div
                              style={{
                                display: "inline-block",
                                width: "100px",
                              }}
                            >
                              {platformValue}
                            </div>
                          }
                          labelAlign="left"
                          rules={[
                            {
                              validator(rule, value, callback) {
                                if (
                                  images[langValue][platformValue].length > 0
                                ) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject();
                                }
                              },
                              message: `${langValue} ${platformValue} 팝업 이미지 등록은 필수입니다.`,
                            },
                          ]}
                        >
                          <Upload
                            fileList={images[langValue][platformValue]}
                            name={`${langValue}_${platformValue}_upload`}
                            multiple={false}
                            maxCount={1}
                            listType="picture"
                            className="upload-list-inline"
                            showUploadList={{ showPreviewIcon: true }}
                            beforeUpload={(file: RcFile) => {
                              const isLt2M = file.size / 1024 / 1024 < 2;
                              if (!isLt2M) {
                                message.error("Image must smaller than 2MB!");
                              }
                              return isLt2M;
                            }}
                            customRequest={({ file, onSuccess }) => {
                              const res = "Ok";
                              onSuccess!(res);
                            }}
                            accept=".jpg, .jpeg, .png, .webp"
                            onChange={handleFileChange(
                              langValue,
                              platformValue
                            )}
                          >
                            <Button
                              icon={<UploadOutlined />}
                            >{`팝업 이미지 업로드`}</Button>
                          </Upload>
                        </Form.Item>
                      )
                    )}
                  </>
                )}
                {popupType === PopupTypeEnum.TEXT && (
                  <>
                    <Form.Item
                      name={`${langValue}Title`}
                      label="공지 제목"
                      rules={[
                        {
                          required: true,
                          message: "공지 제목을 입력하세요.",
                        },
                        { max: 40, message: "최대 40자까지 작성가능합니다." },
                      ]}
                    >
                      <Input maxLength={40} />
                    </Form.Item>
                    <Form.Item
                      name={`${langValue}Content`}
                      label="공지 내용"
                      rules={[
                        {
                          required: true,
                          message: "공지 내용 입력하세요.",
                        },
                        {
                          max: 5000,
                          message: "최대 5000자까지 작성가능합니다.",
                        },
                      ]}
                    >
                      <TextArea maxLength={5000} showCount size="large" />
                    </Form.Item>
                  </>
                )}
                <Divider />
              </Card>
            ))}
          </>

          <Button block htmlType="submit" type="primary" className="mt-5">
            공지사항 { isCreateForm ? "생성": "수정" }
          </Button>
        </Form>
      </HContent>
    </>
  );
}
