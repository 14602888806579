import useNotification from "antd/es/notification/useNotification";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { AxiosError } from "axios";
import React, { useLayoutEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ClinicalTrialTrackType } from "./type";
import { LPPUrls } from "../../../../LPPUrls";
import { LPPAXIOS } from "../../../../framework/api/core.api";
import { LoadingSpin } from "../../../../components/common/Loading";
import { Header } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import {
  Breadcrumb,
  Button,
  Col,
  Collapse,
  Descriptions,
  Image,
  Row,
  Space,
  Tag,
} from "antd";
import { HContent } from "../../../../components/common/HContent";
import DescriptionsItem from "antd/es/descriptions/Item";

const { Panel } = Collapse;

dayjs.extend(duration);

export default function ClinicalTrialTrackDetail() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any | AxiosError | null>(null);
  const [api, contextHolder] = useNotification();
  const [data, setData] = useState<ClinicalTrialTrackType>();
  const onClickBack = () => {
    navigate(LPPUrls.Admin.ClinicalTrialTrack.Main.url());
  };
  const onClickUpdate = () => {
    navigate(LPPUrls.Admin.ClinicalTrialTrack.Update.url(id!));
  };
  useLayoutEffect(() => {
    setLoading(true);
    LPPAXIOS.get<ClinicalTrialTrackType>(`/admin/clinical-trial/track/${id}`)
      .then((resolve) => {
        setData(resolve.data);
      })
      .catch((error) => {
        api.error({ message: `clinical trial track was not found ${id}` });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);
  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm bg-white">
        <Title level={4}>사운드 관리</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.ClinicalTrialTrack.Main.url()}>사운드</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>사운드 상세페이지</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Space className="flex justify-end w-full">
          <Button onClick={onClickUpdate}>수정</Button>
          <Button onClick={onClickBack}>뒤로 가기</Button>
        </Space>
        <Descriptions
          title="음원 이미지"
          bordered
          labelStyle={{ width: "100px" }}
        >
          <Descriptions.Item label="고화질 이미지">
            <Image
              src={`${process.env.REACT_APP_IMG_ENDPOINT}/${data?.artwork}`}
              alt="Artwork"
              width={300} // 고정된 너비
              height={300} // 고정된 높이
              style={{ objectFit: "contain" }} // 비율 유지하며 자르기
            />
          </Descriptions.Item>
          <Descriptions.Item label="Thumbnail">
            <Image
              src={`${process.env.REACT_APP_IMG_ENDPOINT}/${data?.thumbnail}`}
              alt="Thumbnail"
              width={300} // 고정된 너비
              height={300} // 고정된 높이
              style={{ objectFit: "contain" }} // 비율 유지하며 자르기
            />
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          bordered
          title="사운드 정보"
          column={1}
          className="mt-5"
          labelStyle={{ width: "180px" }}
        >
          <Descriptions.Item label="사운드 플레이">
            <audio
              controls
              src={`${process.env.REACT_APP_IMG_ENDPOINT}/${data?.url}`}
              style={{ minWidth: "300px", maxWidth: "100%", width: "100%" }}
            />
          </Descriptions.Item>
          <Descriptions.Item label="사운드 플레이 시간">
            {data?.duration
              ? (() => {
                  const dur = dayjs.duration(data?.duration, "seconds"); // 초 단위로 duration 생성

                  const formattedDuration = [
                    dur.days() > 0
                      ? `${dur.days()} day${dur.days() > 1 ? "s" : ""}`
                      : "",
                    dur.hours() > 0
                      ? `${dur.hours()} hour${dur.hours() > 1 ? "s" : ""}`
                      : "",
                    dur.minutes() > 0
                      ? `${dur.minutes()} minute${dur.minutes() > 1 ? "s" : ""}`
                      : "",
                    dur.seconds() > 0
                      ? `${dur.seconds()} second${dur.seconds() > 1 ? "s" : ""}`
                      : "",
                  ]
                    .filter(Boolean) // 빈 문자열을 필터링하여 제거
                    .join(" "); // 공백으로 구분하여 결합

                  return formattedDuration;
                })()
              : null}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions
          bordered
          title="음원 정보"
          column={1}
          className="mt-5"
          labelStyle={{ width: "180px" }}
        >
          <DescriptionsItem label="작성자">{data?.writer}</DescriptionsItem>
          <DescriptionsItem label="음원 카테고리">
            {data?.category}
          </DescriptionsItem>
          <DescriptionsItem label="음원 상태">{data?.status}</DescriptionsItem>
          <DescriptionsItem label="음원 일자">
            <Descriptions bordered column={2}>
              <DescriptionsItem label="생성일">
                {new Date(data?.createdAt ?? "").toLocaleString()}
              </DescriptionsItem>
              <DescriptionsItem label="업데이트 일">
                {new Date(data?.updatedAt ?? "").toLocaleString()}
              </DescriptionsItem>
            </Descriptions>
          </DescriptionsItem>
          <DescriptionsItem label="음원 입력 정보">
            <Collapse defaultActiveKey={["ko", "en"]} collapsible="header">
              <Panel header="한국어" key="ko">
                <p>이름:{data?.titleKo}</p>
                <p>소개글:{data?.descriptionKo}</p>
                <p>
                  {data?.tagKo ? (
                    <>
                      태그:{" "}
                      {data?.tagKo.split(",").map((item, index) => (
                        <Tag key={`ko-tag-${index}`}>#{item}</Tag>
                      ))}
                    </>
                  ) : null}
                </p>
              </Panel>
              <Panel header="영어" key="en">
                <p>이름:{data?.titleEn}</p>
                <p>소개글:{data?.descriptionEn}</p>
                <p>
                  {data?.tagEn ? (
                    <>
                      태그:{" "}
                      {data?.tagEn.split(",").map((item, index) => (
                        <Tag key={`ko-tag-${index}`}>#{item}</Tag>
                      ))}
                    </>
                  ) : null}
                </p>
              </Panel>
            </Collapse>
          </DescriptionsItem>
        </Descriptions>
      </HContent>
    </>
  );
}
