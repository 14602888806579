import Input from "antd/lib/input";
import notification from "antd/lib/notification";
import Pagination, { PaginationProps } from "antd/lib/pagination";
import Space from "antd/lib/space";
import Table from "antd/lib/table";
import Tag from "antd/lib/tag";
import Typography from "antd/lib/typography";
import Button from "antd/lib/button";

import { Content } from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";
import axios from "axios";
import { useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AppleTag } from "../../../components/common/AppleTag";
import { EmailAccountTag } from "../../../components/common/EmailAccountTag";
import { FaceBookTag } from "../../../components/common/FaceBookTag";
import { GoogleTag } from "../../../components/common/GoogleTag";
import Header from "../../../components/common/Header";
import { KakaoTag } from "../../../components/common/KakaoTag";
import { NaverTag } from "../../../components/common/NaverTag";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";

import { timeConverter } from "../../../utils/dateTimeConveter";
import { preventSpaceBar } from "../../../utils/inputOnlyNumber";
import {
  filterNonEmptyProperties,
  paramsFilter,
} from "../../../utils/paramsFilter";
import { LoginType, SubscriptionType, TABLERESPONSE, USERTABLE } from "./type";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";

const { Title } = Typography;

export default function TablePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const keyword = params.get("keyword");
  const order = params.get("order");
  const sort = params.get("sort");
  const type = params.get("type");
  const subscriptionType = params.get("subscriptionType");

  const [data, setData] = useState<USERTABLE[]>([]);
  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const TableColumn: ColumnsType<USERTABLE> = [
    {
      title: "닉네임",
      dataIndex: "nickName",
      sorter: true,
      defaultSortOrder:
        sort === "nickName" ? (order === "ASC" ? "ascend" : "descend") : null,
    },

    {
      title: "가입일자",
      dataIndex: "createdAt",
      width: 150,
      sorter: true,
      defaultSortOrder:
        sort === "createdAt" ? (order === "ASC" ? "ascend" : "descend") : null,
      render: (_, record) => timeConverter(record.createdAt).slice(0, 10),
    },
    {
      title: "가입유형",
      dataIndex: "type",
      filters: [
        {
          text: <GoogleTag />,
          value: LoginType.GOOGLE,
        },
        {
          text: <AppleTag />,
          value: LoginType.APPLE,
        },
        {
          text: <NaverTag />,
          value: LoginType.NAVER,
        },
        {
          text: <KakaoTag />,
          value: LoginType.KAKAO,
        },
        {
          text: <FaceBookTag />,
          value: LoginType.FACEBOOK,
        },
        {
          text: <EmailAccountTag />,
          value: LoginType.EMAIL,
        },
      ],
      filterMultiple: false,
      defaultFilteredValue: type === null ? [] : [type],

      render: (_, record) => {
        if (record.type === LoginType.GOOGLE) return <GoogleTag />;
        if (record.type === LoginType.APPLE) return <AppleTag />;
        if (record.type === LoginType.NAVER) return <NaverTag />;
        if (record.type === LoginType.KAKAO) return <KakaoTag />;
        if (record.type === LoginType.FACEBOOK) return <FaceBookTag />;
        if (record.type === LoginType.EMAIL) return <EmailAccountTag />;

        return null;
      },
    },
    {
      title: "이메일",
      dataIndex: "email",
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "email" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "구독유무",
      dataIndex: "currentSubscription",
      key: "subscriptionType",
      filters: [
        {
          text: <Tag color="green">구독중</Tag>,
          value: SubscriptionType.SUBSCRIPTION,
        },
        {
          text: <Tag color="blue">쿠폰 구독중</Tag>,
          value: SubscriptionType.COUPON_SUBSCRIPTION,
        },
        {
          text: <Tag>미구독</Tag>,
          value: SubscriptionType.NON_SUBSCRIPTION,
        },
      ],
      filterMultiple: false,
      defaultFilteredValue: subscriptionType === null ? [] : [subscriptionType],

      render: (_, record) => {
        if (record.currentSubscription) {
          if (record.currentSubscription.isCoupon) {
            return <Tag color="blue">쿠폰 구독중</Tag>;
          } else {
            return <Tag color="green">구독중</Tag>;
          }
        } else {
          return <Tag>미구독</Tag>;
        }
      },
    },
  ];

  const handleExcel = async () => {
    const workbook = new Workbook();
    const userSheet = workbook.addWorksheet("사용자 리스트 조회");

    userSheet.columns = [
      { header: "Id", key: "id", width: 10 },
      { header: "닉네임", key: "nickname", width: 32 },
      { header: "가입일", key: "createdAt", width: 20 },
      { header: "가입유형", key: "type", width: 32 },
      { header: "이메일", key: "email", width: 32 },
      { header: "구독유무", key: "currentSubscription", width: 32 },
    ];
    data.forEach((item, idx) => {
      userSheet.addRow({
        id: item.id,
        nickname: item.nickName,
        createdAt: timeConverter(item.createdAt).slice(0, 10),
        type: item.type,
        email: item.email,
        currentSubscription: !item.currentSubscription
          ? "미구독"
          : item.currentSubscription.isCoupon
          ? "쿠폰 구독중"
          : "구독중",
      });
    });

    // 다운로드
    const mimeType = {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    };
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], mimeType);
    saveAs(blob, `사용자 리스트 ${currentPage}페이지.xlsx`);
  };

  const handleTableChange = (value: any, filter: any, sorter: any) => {
    let order =
      sorter.order === null ? null : sorter.order === "ascend" ? "ASC" : "DESC";
    let sort = sorter.field ?? null;

    let result = filterNonEmptyProperties(filter);

    handleChangeSort(sort, order, result);
  };

  const handleChangeSort = (
    sort: null | string,
    order: null | string,
    result: { [key: string]: string | null }
  ) => {
    navigate(
      LPPUrls.Admin.Users.Main.url(
        currentPage,
        paramsFilter({ keyword: keyword, sort: sort, order: order, ...result })
      )
    );
    setCurrentPage(currentPage);
  };

  const handleOnSearchFilter = (value: string) => {
    navigate(
      LPPUrls.Admin.Users.Main.url(
        1,
        paramsFilter({
          keyword: value,
          sort: sort,
          order: order,
          type: type,
          subscriptionType,
        })
      )
    );
    setCurrentPage(1);
  };
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      LPPUrls.Admin.Users.Main.url(
        page,
        paramsFilter({
          keyword: keyword,
          sort: sort,
          order: order,
          type: type,
          subscriptionType,
        })
      )
    );
    setCurrentPage(page);
  };

  useLayoutEffect(() => {
    setLoading(true);
    LPPAXIOS.get<TABLERESPONSE>(
      `/admin/users?` +
        paramsFilter({
          page: currentPage,
          perPage: 25,
          keyword: keyword,
          sort: sort,
          order: order,
          type: type,
          subscriptionType,
        })
    )
      .then((res) => {
        setData(res.data.list);
        setTotal(res.data.total);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: `Notification ${error.code}`,
            description: `${error.message}`,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [api, currentPage, order, sort, type, keyword, subscriptionType]);

  return (
    <>
      <Header className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm">
        <Title level={4}>사용자 관리</Title>
      </Header>
      <Content className="m-2 bg-white">
        <Table
          rowClassName={() => "cursor-pointer"}
          loading={loading}
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={data}
          columns={TableColumn}
          onChange={(val: any, filter: any, sorter: any, extra: any) => {
            handleTableChange(val, filter, sorter);
          }}
          onRow={(record, _) => {
            return {
              onClick: () => {
                navigate(LPPUrls.Admin.Users.Detail.url(record.id));
              }, // click row
            };
          }}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Space>
                <Title level={4}>사용자 리스트</Title>
                <Button onClick={handleExcel}>엑셀 다운로드</Button>
              </Space>
              <Input.Search
                defaultValue={keyword ?? ""}
                style={{ width: 300 }}
                placeholder="회원의 이름 또는 이메일을 검색하세요"
                onSearch={handleOnSearchFilter}
                onInput={preventSpaceBar}
              />
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
        />
      </Content>
    </>
  );
}
