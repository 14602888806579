import {
  Button,
  Divider,
  Form,
  Input,
  Radio,
  Result,
  Select,
  Upload,
  UploadProps,
  Card,
  Breadcrumb,
} from "antd";
import Header from "../../../components/common/Header";
import useNotification from "antd/es/notification/useNotification";

import { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HContent } from "../../../components/common/HContent";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";
import { TRACKDETAIL } from "./type";
import { UploadFile } from "antd/lib/upload/interface";
import { findCategoryS3Update } from "../../../utils/data";
import { InboxOutlined } from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import { handleUploadCompMultiFilesV2 } from "../../../utils/uploadImgToS3";
import { getItem, soundPillAdmin } from "../../../utils/localStorage";
import { CategoryFromApiType } from "../../../types/CategoryFromApiType";

const { Dragger } = Upload;
export default function UpdatePage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any | AxiosError | null>(null);
  const [api, contextHolder] = useNotification();
  const [form] = Form.useForm();

  const [artwork, setArtwork] = useState<UploadFile[]>([]);
  const [thumbnail, setThumbnail] = useState<UploadFile[]>([]);
  const [musicFile, setMusicFile] = useState<UploadFile[]>([]);
  const [duration, setDuration] = useState(0);
  const [trialMusicFile, setTrialMusicFile] = useState<UploadFile[]>([]);
  const [trialMusicDuration, setTrialMusicDuration] = useState(0);
  const [category, setCategory] = useState<
    {
      label: CategoryFromApiType["categoryKo"];
      value: CategoryFromApiType["id"];
      legacyId?: CategoryFromApiType["legacyId"];
    }[]
  >([]);

  const admin = getItem(soundPillAdmin);

  const handleUpdateTrack = async (formValue: any) => {
    setLoading(true);
    try {
      const legacyIdOfCategory = category.find(
        (c) => c.value === formValue.categoryId
      )?.legacyId;
      const artworkImage =
        (await handleUploadCompMultiFilesV2(
          artwork,
          "image",
          findCategoryS3Update(legacyIdOfCategory)
        )) ?? [];
      const thumbnailImage =
        (await handleUploadCompMultiFilesV2(
          thumbnail,
          "thumbnail",
          findCategoryS3Update(legacyIdOfCategory)
        )) ?? [];
      const soundSrc =
        (await handleUploadCompMultiFilesV2(
          musicFile,
          "audio",
          findCategoryS3Update(legacyIdOfCategory)
        )) ?? [];
      const trialMusicSrc =
        (await handleUploadCompMultiFilesV2(
          trialMusicFile,
          "trial",
          findCategoryS3Update(legacyIdOfCategory)
        )) ?? [];

      const result = {
        ...formValue,
        id,
        duration: duration,
        artwork: artworkImage[0],
        thumbnail: thumbnailImage[0],
        url: soundSrc[0],
        tagKo: formValue.tagKo.join(","),
        tagEn: formValue.tagEn.join(","),
        trialMusicUrl: trialMusicSrc[0],
        trialMusicDuration,
        adminId: admin.id,
      };

      await LPPAXIOS.put(`/admin/track`, result);
      api.success({
        message: "사운드 수정이 완료되었습니다.",
      });
      setTimeout(() => {
        navigate(LPPUrls.Admin.Sounds.Detail.url(id!), { replace: true });
      }, 500);
    } catch (error) {
      console.log("수정 에러:", error);
      api.error({
        message: "사운드 수정이 실패하였습니다.",
      });
    } finally {
      setLoading(false);
    }
  };
  const startEdit = (imageList: string[], stateFunc: Function) => {
    const imageFileList = imageList;
    const arr = [];
    for (let i = 0; i < imageFileList.length; i++) {
      const previewFile: UploadFile = {
        uid: `done file ${i}`,
        name: imageFileList[i],
        status: "done",
        url: `${process.env.REACT_APP_IMG_ENDPOINT}/${imageFileList[i]}`,
      };
      arr.push(previewFile);
    }
    stateFunc(arr);
  };

  const onChangeArtWork: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    const newUpdatedFileList = newFileList.map((file) => {
      if (file.status !== "done") {
        file.status = "done";
      }
      return file;
    });
    setArtwork(newUpdatedFileList);
  };
  const onChangeThumbNail: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    const newUpdatedFileList = newFileList.map((file) => {
      if (file.status !== "done") {
        file.status = "done";
      }
      return file;
    });
    setThumbnail(newUpdatedFileList);
  };
  const onChangeMusicFile: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    const newUpdatedFileList = newFileList.map((file) => {
      if (file.status !== "done") {
        // 새로운 파일을 위한 메타데이터 처리
        if (file.originFileObj) {
          const audio = new Audio();
          audio.src = URL.createObjectURL(file.originFileObj);
          audio.onloadedmetadata = () => {
            // 파일 리스트를 업데이트하기 전에 파일의 duration을 가져옵니다.
            const duration = audio.duration;
            setDuration(Math.trunc(duration));

            // 메모리 누수를 방지하기 위해 URL을 정리합니다.
            URL.revokeObjectURL(audio.src);

            // 이제 파일 상태를 'done'으로 설정합니다.
            file.status = "done";

            // 상태 업데이트
          };
        }
      }
      return file;
    });
    setMusicFile(newUpdatedFileList);
  };
  const onChangeTrialMusicFile: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    const newUpdatedFileList = newFileList.map((file) => {
      if (file.status !== "done") {
        // 새로운 파일을 위한 메타데이터 처리
        if (file.originFileObj) {
          const audio = new Audio();
          audio.src = URL.createObjectURL(file.originFileObj);
          audio.onloadedmetadata = () => {
            // 파일 리스트를 업데이트하기 전에 파일의 duration을 가져옵니다.
            const duration = audio.duration;
            setTrialMusicDuration(Math.trunc(duration));

            // 메모리 누수를 방지하기 위해 URL을 정리합니다.
            URL.revokeObjectURL(audio.src);

            // 이제 파일 상태를 'done'으로 설정합니다.
            file.status = "done";
          };
        }
      }
      return file;
    });
    setTrialMusicFile(newUpdatedFileList);
  };

  useLayoutEffect(() => {
    setLoading(true);
    LPPAXIOS.get<TRACKDETAIL>(`/admin/track/${id}`)
      .then((resolve) => {
        startEdit([resolve.data.artwork], setArtwork);
        startEdit([resolve.data.thumbnail], setThumbnail);
        startEdit([resolve.data.url], setMusicFile);
        if (resolve.data.trialTrackInfo) {
          startEdit([resolve.data.trialTrackInfo.url], setTrialMusicFile);
          setTrialMusicDuration(resolve.data.trialTrackInfo.duration);
        }
        setDuration(resolve.data.duration);

        const findCategoryId = resolve.data.categoryInfo
          ? resolve.data.categoryInfo.id
          : "none";
        form.setFieldsValue({
          ...resolve.data,
          //Ko
          titleKo: resolve.data.title.ko,
          descriptionKo: resolve.data.description.ko,
          tagKo: resolve.data.tag.ko,
          //En
          titleEn: resolve.data.title.en,
          descriptionEn: resolve.data.description.en,
          tagEn: resolve.data.tag.en,
          //category
          categoryId: findCategoryId,
          trialMusicUrl: resolve.data?.trialTrackInfo?.url ?? undefined,
        });
        LPPAXIOS.get(`/admin/category`)
          .then((res) => {
            const categoryFromApi = res.data.list.map(
              (category: CategoryFromApiType) => ({
                label: category.categoryKo,
                value: category.id,
                legacyId: category.legacyId,
              })
            );
            setCategory([
              ...categoryFromApi,
              { label: "카테고리 없음", value: "none" },
            ]);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        return setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);
  if (error !== null) return <Result status={404} title={"404"} />;
  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm">
        <Title level={4}>사운드 수정</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Sounds.Main.url()}>사운드</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>사운드 수정</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Form form={form} onFinish={handleUpdateTrack}>
          <Title level={4}>언어</Title>
          <Card title="한국어">
            <Form.Item name="titleKo" label="제목">
              <Input />
            </Form.Item>
            <Form.Item name="descriptionKo" label="설명">
              <Input />
            </Form.Item>
            <Form.Item name="tagKo" label="태그">
              <Select mode="tags" />
            </Form.Item>
          </Card>
          <Card title="영어">
            <Form.Item name="titleEn" label="제목">
              <Input />
            </Form.Item>
            <Form.Item name="descriptionEn" label="설명">
              <Input />
            </Form.Item>
            <Form.Item name="tagEn" label="태그">
              <Select mode="tags" />
            </Form.Item>
          </Card>
          <Divider />
          <Form.Item name="categoryId" label="카테고리">
            <Select options={category} />
          </Form.Item>
          <Form.Item name="isFree" label="구독음원 설정">
            <Radio.Group>
              <Radio value={true}>무료음원</Radio>
              <Radio value={false}>구독음원</Radio>
            </Radio.Group>
          </Form.Item>
          <Title level={4}>미디어 파일</Title>
          <Form.Item
            name="artwork"
            label="고화질 이미지"
            extra="고화질 이미지 등록"
            rules={[
              {
                validator(rule, value, callback) {
                  if (artwork.length > 0) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject();
                  }
                },
                message: "고화질 이미지 등록은 필수입니다.",
              },
            ]}
          >
            <Upload
              fileList={artwork}
              name="artwork"
              multiple={false}
              maxCount={1}
              listType="picture"
              className="upload-list-inline"
              showUploadList={{ showPreviewIcon: true }}
              customRequest={({ file, onSuccess }) => {
                const res = "Ok";
                onSuccess!(res);
              }}
              accept=".jpg, .jpeg, .png, .webp"
              onChange={onChangeArtWork}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="thumbnail"
            label="저화질 이미지"
            extra="저화질 이미지 등록"
            rules={[
              {
                validator(rule, value, callback) {
                  if (thumbnail.length > 0) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject();
                  }
                },
                message: "썸네일 이미지 등록은 필수입니다.",
              },
            ]}
          >
            <Upload
              fileList={thumbnail}
              name="thumbnail"
              multiple={false}
              maxCount={1}
              listType="picture"
              className="upload-list-inline"
              showUploadList={{ showPreviewIcon: true }}
              customRequest={({ file, onSuccess }) => {
                const res = "Ok";
                onSuccess!(res);
              }}
              accept=".jpg, .jpeg, .png, .webp"
              onChange={onChangeThumbNail}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="url"
            label="음원 파일"
            rules={[
              {
                validator(rule, value, callback) {
                  if (musicFile.length > 0) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject();
                  }
                },
                message: "음원소스 등록은 필수입니다.",
              },
            ]}
          >
            <Dragger
              fileList={musicFile}
              name="file"
              accept="audio/mp3"
              maxCount={1}
              multiple={false}
              onChange={onChangeMusicFile}
              onDrop={(file) => {
                console.log(file.dataTransfer.files);
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibited from
                uploading company data or other banned files.
              </p>
            </Dragger>
          </Form.Item>
          <Form.Item name="trialMusicUrl" label="체험 음원">
            <Dragger
              fileList={trialMusicFile}
              name="file"
              accept="audio/mp3"
              maxCount={1}
              multiple={false}
              onChange={onChangeTrialMusicFile}
              onDrop={(file) => {
                console.log(file.dataTransfer.files);
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibited from
                uploading company data or other banned files.
              </p>
            </Dragger>
          </Form.Item>
          <Button block htmlType="submit" type="primary">
            사운드 수정
          </Button>
        </Form>
      </HContent>
    </>
  );
}

function UploadButton() {
  return (
    <div>
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
}
