import Descriptions from "antd/lib/descriptions";
import Result from "antd/lib/result";
import Image from "antd/lib/image";
import Collapse from "antd/lib/collapse";
import Space from "antd/lib/space";
import Button from "antd/lib/button";
import Tag from "antd/lib/tag";
import Typography from "antd/lib/typography";
import Popconfirm from "antd/lib/popconfirm";
import Card from "antd/lib/card";
import Breadcrumb from "antd/es/breadcrumb/Breadcrumb";
import useNotification from "antd/es/notification/useNotification";

import Title from "antd/es/typography/Title";
import { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HContent } from "../../../components/common/HContent";
import Header from "../../../components/common/Header";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";
import { DETAILDOCTOR } from "./type";
import { TableCategory } from "../Category/type";

const { Panel } = Collapse;

export default function DetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any | AxiosError | null>(null);
  const [api, contextHolder] = useNotification();

  const [data, setData] = useState<DETAILDOCTOR>();
  const [categories, setCategories] = useState<TableCategory[]>([]);

  const onClickBack = () => {
    navigate(LPPUrls.Admin.ParticipantManagement.Main.url());
  };
  const onClickUpdate = () => {
    navigate(LPPUrls.Admin.ParticipantManagement.Update.url(id!));
  };
  const onClickDelete = () => {
    setLoading(true);
    LPPAXIOS.post(`/admin/doctor/delete/multiple`, {
      doctorIds: [id],
    })
      .then((resolve) => {
        api.success({
          message: "의사 삭제 성공",
        });
        setTimeout(() => {
          navigate(LPPUrls.Admin.ParticipantManagement.Main.url(), {
            replace: true,
          });
        }, 600);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          api.error({
            message: "잘못된 요청입니다.",
          });
        }
        if (error.response.status === 403) {
          api.error({
            message: "삭제 권한이 없습니다.",
          });
        }
        if (error.respone.status === 404) {
          api.error({
            message: "요청을 찾을 수 없습니다.",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useLayoutEffect(() => {
    setLoading(true);
    LPPAXIOS.get<DETAILDOCTOR>(`/admin/doctor/${id}`)
      .then((resolve) => {
        setData(resolve.data);
      })
      .catch((error) => {
        console.log(error);
        return setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  useLayoutEffect(() => {
    setLoading(true);
    LPPAXIOS.get(`/admin/category`)
      .then((res) => {
        setCategories(res.data.list);
      })
      .catch((error) => {
        console.log(error);
        return setError(error);
      })
      .finally(() => {
        console.log(categories);
        setLoading(false);
      });
  }, [id]);

  if (error !== null && error.response.status === 404)
    return (
      <Result
        status={404}
        title={"404"}
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );
  if (error !== null && error.response.status === 500)
    return (
      <Result
        status={500}
        title={"500"}
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );
  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm">
        <Title level={4}>참여의료진 상세</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.ParticipantManagement.Main.url()}>
              참여 의료진 조회
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>참여의료진 상세조회</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Space className="flex justify-end w-full">
          <Button onClick={onClickUpdate}>수정</Button>
          <Popconfirm
            title="참여 의료진 삭제"
            description="참여의료진을 삭제하시겠습니까?"
            onConfirm={onClickDelete}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>삭제</Button>
          </Popconfirm>
        </Space>

        <Image
          src={`${process.env.REACT_APP_IMG_ENDPOINT}/${data?.image}`}
          width={300}
        />

        <Collapse defaultActiveKey={["ko"]} collapsible="header">
          <Panel header="한국어" key="ko">
            <Descriptions column={3} bordered>
              <Descriptions.Item label="이름">{data?.koName}</Descriptions.Item>
              <Descriptions.Item label="전공">
                {data?.koDepartment}
              </Descriptions.Item>
              <Descriptions.Item label="직급">
                {data?.koPosition}
              </Descriptions.Item>

              <Descriptions.Item label="소개" span={24}>
                <Typography className="whitespace-pre-line ">
                  {data?.koDescription.split("\n").map((item, index) => (
                    <Typography key={`koDescription-${index}`}>
                      {item}
                    </Typography>
                  ))}
                </Typography>
              </Descriptions.Item>

              <Descriptions.Item label="이력" span={24}>
                <Typography className="whitespace-pre-line ">
                  {data?.koHistory.split("\n").map((item, index) => (
                    <Typography key={`koHistory-${index}`}>{item}</Typography>
                  ))}
                </Typography>
              </Descriptions.Item>
            </Descriptions>
          </Panel>
          <Panel header="영어" key="en">
            <Descriptions column={2} bordered>
              <Descriptions.Item label="이름">{data?.enName}</Descriptions.Item>
              <Descriptions.Item label="직급">
                {data?.enPosition}
              </Descriptions.Item>
              <Descriptions.Item label="소개" span={24}>
                <Typography className="whitespace-pre-line ">
                  {data?.enDescription.split("\n").map((item, index) => (
                    <Typography key={`enDescription-${index}`}>
                      {item}
                    </Typography>
                  ))}
                </Typography>
              </Descriptions.Item>

              <Descriptions.Item label="이력" span={24}>
                <Typography className="whitespace-pre-line ">
                  {data?.enHistory.split("\n").map((item, index) => (
                    <Typography key={`enHistory-${index}`}>{item}</Typography>
                  ))}
                </Typography>
              </Descriptions.Item>
            </Descriptions>
          </Panel>
        </Collapse>
        <Card title="참여 카테고리">
          {data?.categoryIds.map((cid) => {
            const category = categories.find((c) => c.id === cid);
            return category ? <Tag key={cid}>{category.categoryKo}</Tag> : null;
          })}
        </Card>
      </HContent>
    </>
  );
}
