import React, { Key, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ClinicalTrialTrackTableInterface, StatusEnum } from "./type";
import {
  Button,
  notification,
  Pagination,
  PaginationProps,
  Space,
  Typography,
} from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { timeConverter } from "../../../../utils/dateTimeConveter";
import { Content, Header } from "antd/es/layout/layout";
import { LPPUrls } from "../../../../LPPUrls";
import { paramsFilter, urlFilter } from "../../../../utils/paramsFilter";
import { LPPAXIOS } from "../../../../framework/api/core.api";
const { Title } = Typography;

export default function ClinicalTrialTrackTable() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const keyword = params.get("keyword");
  const order = params.get("order");
  const sort = params.get("sort");

  const [data, setData] = useState<ClinicalTrialTrackTableInterface[]>([]);
  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const TableColumn: ColumnsType<ClinicalTrialTrackTableInterface> = [
    {
      title: "타이틀",
      dataIndex: "titleKo",
      render: (_, record) => record.titleKo,
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "titleKo" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "카테고리",
      dataIndex: "category",
      key: "category",
      render: (_, record) => record.category,
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "category" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "상태",
      dataIndex: "status",
      key: "status",
      render: (_, record) => record.status,
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "status" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "작성자",
      dataIndex: "writer",
      key: "writer",
      render: (_, record) => record.writer,
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "writer" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "업로드일",
      dataIndex: "createdAt",
      render: (_, record) => timeConverter(record.createdAt).slice(0, 10),
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "createdAt" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
  ];

  const handleTableChange = (value: any, filter: any, sorter: any) => {
    let order =
      sorter.order === null ? null : sorter.order === "ascend" ? "ASC" : "DESC";
    let sort = sorter && sorter.field ? sorter.field : null;
    let type =
      Array.isArray(filter["type"]) && filter["type"].length
        ? filter["type"][0]
        : null;

    handleChangeSort(sort, order, type);
  };

  const handleChangeSort = (
    sort: null | string,
    order: null | string,

    result: { [key: string]: string | null }
  ) => {
    navigate(
      LPPUrls.Admin.ClinicalTrialTrack.Main.url(
        currentPage,
        paramsFilter({ keyword: keyword, sort: sort, order: order, ...result })
      )
    );
    setCurrentPage(currentPage);
  };

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      LPPUrls.Admin.ClinicalTrialTrack.Main.url(
        page,
        paramsFilter({
          keyword: keyword,
          sort: sort,
          order: order,
        })
      )
    );
    setCurrentPage(page);
  };

    const onPatchStatus = async (status: StatusEnum) => {
      if (selectedRowKeys.length) {
        try {
          setLoading(true);
          let storageUserInfo = localStorage.getItem("app.soundpill.admin.info");
          if (!storageUserInfo) throw new Error("not found storageUserInfo");
          const parseStorageUserInfo = JSON.parse(storageUserInfo);
          for (const id of selectedRowKeys) {
            const result = await LPPAXIOS.patch(
              `/admin/clinical-trial/track/status/${id}`,
              { status, writer: parseStorageUserInfo.id }
            );
            if (!result.data?.success) throw new Error(`api failed ${id}`);
          }
        } catch (error) {
          api.error({ message: "상태 변경 실패" });
        } finally {
          setLoading(false);
          window.location.reload();
        }
      } else {
        api.error({ message: "상태 변경 할 팝업이 없습니다." });
      }
    };

  useLayoutEffect(() => {
    setLoading(true);
    const getList = async () => {
      try {
        const res = await LPPAXIOS.get(
          urlFilter({
            url: `/admin/clinical-trial/tracks`,
            page: currentPage,
            perPage: 25,
            keyword: keyword,
            sort: sort ?? 'createdAt',
            order: order ?? 'DESC',
          })
        );
        setData(res.data.list);
        setTotal(res.data.total);
      } catch (error) {
        api.error({
          message: `An unexpected error occurred`,
        });
      } finally {
        setLoading(false);
      }
    };
    getList();
  }, [api, currentPage, order, sort, keyword]);


  return (
    <>
      {contextHolder}
      <Header className="flex items-center justify-between py-1 my-2 mb-10 drop-shadow-sm bg-white">
        <Title level={4}>사운드</Title>
        <Button
          onClick={() =>
            navigate(LPPUrls.Admin.ClinicalTrialTrack.Create.pathName)
          }
        >
          사운드 생성
        </Button>
      </Header>
      <Content className="m-2 bg-white">
        <Table
          rowClassName={() => "cursor-pointer"}
          loading={loading}
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={data}
          columns={TableColumn}
          rowSelection={{
            selectedRowKeys,
            onChange: (selectedKeys) => {
              setSelectedRowKeys(selectedKeys);
            },
          }}
          onChange={(val: any, filter: any, sorter: any, extra: any) => {
            handleTableChange(val, filter, sorter);
          }}
          onRow={(record, _) => {
            return {
              onClick: () => {
                navigate(
                  LPPUrls.Admin.ClinicalTrialTrack.Detail.url(record.id)
                );
              }, // click row
            };
          }}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Space>
                <Title level={4}>사운드 리스트</Title>
              </Space>
              <Space>
                <Button onClick={() => onPatchStatus(StatusEnum.Active)}>활성</Button>
                <Button onClick={() => onPatchStatus(StatusEnum.Inactive)}>비활성</Button>
              </Space>
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
        ></Table>
      </Content>
    </>
  );
}
