import {
  Button,
  Divider,
  Form,
  Input,
  Result,
  Upload,
  UploadProps,
  Image,
  Card,
  Breadcrumb,
} from "antd";
import Header from "../../../components/common/Header";
import useNotification from "antd/es/notification/useNotification";
import { generateVideoThumbnails } from "@rajesh896/video-thumbnails-generator";

import { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HContent } from "../../../components/common/HContent";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";

import { UploadFile } from "antd/lib/upload/interface";
import { InboxOutlined } from "@ant-design/icons";

import Title from "antd/es/typography/Title";

import { handleUploadCompMultiFilesV2 } from "../../../utils/uploadImgToS3";
import { RcFile } from "antd/es/upload";
import TextArea from "antd/es/input/TextArea";

const { Dragger } = Upload;

export default function UpdatePage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any | AxiosError | null>(null);
  const [api, contextHolder] = useNotification();
  const [form] = Form.useForm();

  const [videoFile, setVideoFile] = useState<UploadFile[]>([]);
  const [thumbnailFile, setThumbnailFile] = useState<UploadFile[]>([]);
  const [categoryName, setCategoryName] = useState();

  const handleUpdateTrack = async (formValue: any) => {
    setLoading(true);
    try {
      const thumbnail =
        (await handleUploadCompMultiFilesV2(
          thumbnailFile,
          "thumbnail",
          null
        )) ?? [];
      const video =
        (await handleUploadCompMultiFilesV2(videoFile, "video", null)) ?? [];

      const result = {
        id: id,
        ...formValue,
        thumbnail: thumbnail[0],
        video: video[0],
      };

      await LPPAXIOS.put(`/admin/category/${id}`, result);
      api.success({
        message: "카테고리 수정이 완료되었습니다.",
      });
      setTimeout(() => {
        navigate(LPPUrls.Admin.Category.Main.url(), { replace: true });
      }, 500);
    } catch (error) {
      api.error({
        message: "카테고리 수정이 실패하였습니다.",
      });
    } finally {
      setLoading(false);
    }
  };
  const startEdit = (imageList: string[], stateFunc: Function) => {
    const imageFileList = imageList;
    const arr = [];
    for (let i = 0; i < imageFileList.length; i++) {
      const previewFile: UploadFile = {
        uid: `done file ${i}`,
        name: imageFileList[i],
        status: "done",
        url: `${process.env.REACT_APP_IMG_ENDPOINT}/${imageFileList[i]}`,
      };
      arr.push(previewFile);
    }
    stateFunc(arr);
  };

  const onChangeVideoFile: UploadProps["onChange"] = async ({
    fileList: newFileList,
  }) => {
    const newUpdatedFileList = await Promise.all(
      newFileList.map(async (file) => {
        if (file.status !== "done" && file.originFileObj) {
          try {
            setLoading(true);

            const thumbnails = await generateVideoThumbnails(
              file.originFileObj,
              1,
              "image/jpeg"
            );

            const thumbnailUrl = thumbnails[0];
            let fileData = convertBase64ToFile(thumbnailUrl, "image/jpeg");
            setThumbnailFile([
              {
                uid: "thumbnail",
                name: "thumbnail",
                status: "done",
                type: "image/jpeg",
                url: thumbnails[0],
                originFileObj: fileData as RcFile,
              },
            ]);

            // UploadFile 타입에는 thumbnail 속성이 없으므로 확장된 타입을 사용할 필요가 있습니다.
            const newFile: UploadFile & { thumbnail?: string } = {
              ...file,
              status: "done",
              // thumbnail: thumbnailUrl, // 썸네일 URL을 추가합니다.
            };
            return newFile;
          } catch (error) {
            console.error("Error generating thumbnail", error);
            // 썸네일 생성에 실패한 경우 기존 파일 정보를 반환합니다.
            return file;
          } finally {
            setLoading(false);
          }
        } else {
          // 이미 처리된 파일이거나 변화가 없는 경우 원본 파일 정보를 반환합니다.
          return file;
        }
      })
    );

    setVideoFile(newUpdatedFileList);
  };

  useLayoutEffect(() => {
    setLoading(true);
    LPPAXIOS.get(`admin/category/${id}`)
      .then((resolve) => {
        startEdit([resolve.data.video ?? null], setVideoFile);
        startEdit([resolve.data.thumbnail ?? null], setThumbnailFile);
        form.setFieldsValue({
          //Ko
          categoryKo: resolve.data.category.ko,
          effectiveUsageKo: resolve.data.effectiveUsage.ko,
          effectIntroductionKo: resolve.data.effectIntroduction.ko,
          firstAccessKo: resolve.data.firstAccess.ko,
          //En
          categoryEn: resolve.data.category.en,
          effectiveUsageEn: resolve.data.effectiveUsage.en,
          effectIntroductionEn: resolve.data.effectIntroduction.en,
          firstAccessEn: resolve.data.firstAccess.en,
        });
        setCategoryName(resolve.data.category.ko);
      })
      .catch((error) => {
        return setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);
  if (error !== null) return <Result status={404} title={"404"} />;
  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm">
        <Title level={4}>{categoryName} 카테고리 수정</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Category.Main.url()}>카테고리 조회</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{categoryName} 카테고리 수정</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Form form={form} onFinish={handleUpdateTrack} layout="vertical">
          <Title level={4}>언어</Title>
          <Card title="한국어">
            <Form.Item name="categoryKo" label="카테고리 이름">
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="effectIntroductionKo"
              label="효과소개"
              rules={[
                {
                  required: true,
                  message: "효과소개를 입력하세요.",
                },
                { max: 10000, message: "최대 10000자까지 작성가능합니다." },
              ]}
            >
              <TextArea maxLength={10000} showCount />
            </Form.Item>
            <Form.Item
              name="effectiveUsageKo"
              label="효과적인 사용법"
              rules={[
                {
                  required: true,
                  message: "효과적인 사용법을 입력하세요.",
                },
                { max: 10000, message: "최대 10000자까지 작성가능합니다." },
              ]}
            >
              <TextArea maxLength={10000} showCount />
            </Form.Item>
            <Form.Item
              name="firstAccessKo"
              label="체험 화면 문구"
              rules={[
                {
                  required: true,
                  message: "체험 이미지에서 보여질 문구를 입력하세요.",
                },
                { max: 50, message: "최대 50자까지 작성가능합니다." },
              ]}
            >
              <Input maxLength={50} />
            </Form.Item>
          </Card>
          <Card title="영어">
            <Form.Item
              name="categoryEn"
              label="카테고리 이름"
              rules={[
                {
                  required: true,
                  message: "카테고리 이름을 입력하세요.",
                },
                { max: 50, message: "최대 50자까지 작성가능합니다." },
              ]}
            >
              <Input maxLength={50} />
            </Form.Item>
            <Form.Item
              name="effectIntroductionEn"
              label="효과소개"
              rules={[
                {
                  required: true,
                  message: "효과소개를 입력하세요.",
                },
                { max: 10000, message: "최대 10000자까지 작성가능합니다." },
              ]}
            >
              <TextArea maxLength={10000} showCount />
            </Form.Item>
            <Form.Item
              name="effectiveUsageEn"
              label="효과적인 사용법"
              rules={[
                {
                  required: true,
                  message: "효과적인 사용법을 입력하세요.",
                },
                { max: 10000, message: "최대 10000자까지 작성가능합니다." },
              ]}
            >
              <TextArea maxLength={10000} showCount />
            </Form.Item>
            <Form.Item
              name="firstAccessEn"
              label="체험 화면 문구"
              rules={[
                {
                  required: true,
                  message: "체험 이미지에서 보여질 문구를 입력하세요.",
                },
                { max: 50, message: "최대 50자까지 작성가능합니다." },
              ]}
            >
              <Input maxLength={50} />
            </Form.Item>
          </Card>
          <Divider />

          <Title level={4}>미디어 파일</Title>

          <Form.Item
            name="video"
            label="효과소개 영상 첨부파일"
            rules={[
              {
                validator(rule, value, callback) {
                  if (videoFile.length > 0) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject();
                  }
                },
                message: "효과소개 영상 등록은 필수입니다.",
              },
            ]}
          >
            <Dragger
              fileList={videoFile}
              name="file"
              accept="video/*"
              maxCount={1}
              multiple={false}
              onChange={onChangeVideoFile}
              onDrop={(file) => {
                console.log(file.dataTransfer.files);
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                해당 영역에 클릭 또는 드래그하여 파일을 업로드합니다.
              </p>
              <p className="ant-upload-hint">
                동영상을 제외한 파일업로드를 제한합니다.
              </p>
            </Dragger>
          </Form.Item>

          {thumbnailFile.length > 0 && (
            <Image src={thumbnailFile[0].url} height={100} />
          )}

          <Button block htmlType="submit" type="primary">
            수정하기
          </Button>
        </Form>
      </HContent>
    </>
  );
}

const convertBase64ToFile = (base64String: string, filename: string): File => {
  const arr = base64String.split(",");
  const mimeMatch = arr[0].match(/:(.*?);/);

  if (!mimeMatch) {
    throw new Error("Base64 string is missing MIME type");
  }

  const mime = mimeMatch[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};
