import {
  Button,
  Divider,
  Form,
  Input,
  Select,
  Upload,
  UploadProps,
  message,
  Card,
  Breadcrumb,
  Result,
} from "antd";
import Header from "../../../components/common/Header";
import useNotification from "antd/es/notification/useNotification";

import { AxiosError } from "axios";
import { useEffect, useLayoutEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HContent } from "../../../components/common/HContent";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";

import { UploadFile } from "antd/lib/upload/interface";

import { UploadOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import { RcFile } from "antd/es/upload";
import { handleUploadCompMultiFilesV2 } from "../../../utils/uploadImgToS3";
import TextArea from "antd/es/input/TextArea";
import { DETAILDOCTOR } from "./type";
import { TableCategory } from "../Category/type";

export default function UpdatePage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = useNotification();
  const [form] = Form.useForm();
  const [error, setError] = useState<any | AxiosError | null>(null);

  const [data, setData] = useState<DETAILDOCTOR>();
  const [artwork, setArtwork] = useState<UploadFile[]>([]);
  const [categories, setCategories] = useState<TableCategory[]>([]);

  const handleUpdateTrack = async (formValue: any) => {
    setLoading(true);
    try {
      const artworkImage =
        (await handleUploadCompMultiFilesV2(artwork, "image", null)) ?? [];

      const { artwork: tempArtwork, ...rest } = formValue;
      const result = {
        ...rest,
        image: artworkImage[0],
        viewYn: "Y",
        dlYn: "N",
      };

      await LPPAXIOS.put(`/admin/doctor/${id}`, result);

      api.success({
        message: "참여 의료진 수정이 완료되었습니다.",
      });
      setTimeout(() => {
        navigate(LPPUrls.Admin.ParticipantManagement.Main.url(), {
          replace: true,
        });
      }, 500);
    } catch (error) {
      api.error({
        message: "참여 의료진 수정이 실패하였습니다.",
      });
    } finally {
      setLoading(false);
    }
  };

  const onChangeArtWork: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    const newUpdatedFileList = newFileList.map((file) => {
      if (file.status !== "done") {
        file.status = "done";
      }
      return file;
    });
    setArtwork(newUpdatedFileList);
  };

  const startEdit = (imageList: string[], stateFunc: Function) => {
    const imageFileList = imageList;
    const arr = [];
    for (let i = 0; i < imageFileList.length; i++) {
      const previewFile: UploadFile = {
        uid: `done file ${i}`,
        name: imageFileList[i],
        status: "done",
        url: `${process.env.REACT_APP_IMG_ENDPOINT}/${imageFileList[i]}`,
      };
      arr.push(previewFile);
    }
    stateFunc(arr);
  };

  useLayoutEffect(() => {
    setLoading(true);
    LPPAXIOS.get(`/admin/doctor/${id}`)
      .then((resolve) => {
        setData(resolve.data);
        startEdit([resolve.data.image], setArtwork);
        form.setFieldsValue({
          ...resolve.data,
        });
      })
      .catch((error) => {
        // if (axios.isAxiosError(error)) {
        //   return setError(error);
        // }
        return setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  useLayoutEffect(() => {
    setLoading(true);
    LPPAXIOS.get(`/admin/category`)
      .then((res) => {
        setCategories(res.data.list);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    form.setFieldsValue({
      categoryId: 7,
      isFree: false,
    });
  }, []);
  if (error !== null) return <Result status={404} title={"404"} />;
  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm">
        <Title level={4}>참여의료진 수정</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.ParticipantManagement.Main.url()}>
              참여 의료진 조회
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>참여의료진 수정</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Form form={form} onFinish={handleUpdateTrack}>
          <Title level={4}>언어</Title>

          <Card title="한국어">
            <Form.Item
              name="koName"
              label="이름"
              rules={[
                {
                  required: true,
                  message: "이름을 입력하세요.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="koDepartment"
              label="전공"
              rules={[
                {
                  required: true,
                  message: "전공을 입력하세요.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="koPosition"
              label="직급"
              rules={[
                {
                  required: true,
                  message: "직급을 입력하세요.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="koDescription"
              label="소개"
              rules={[
                {
                  required: true,
                  message: "소개내용을 입력하세요.",
                },
              ]}
            >
              <TextArea />
            </Form.Item>
            <Form.Item
              name="koHistory"
              label="이력"
              rules={[
                {
                  required: true,
                  message: "이름을 입력하세요.",
                },
              ]}
            >
              <TextArea />
            </Form.Item>
          </Card>

          <Card title="영어">
            <Form.Item
              name="enName"
              label="이름"
              rules={[
                {
                  required: true,
                  message: "이름을 입력하세요.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="enDepartment"
              label="전공"
              rules={[
                {
                  required: true,
                  message: "이름을 입력하세요.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="enPosition"
              label="직급"
              rules={[
                {
                  required: true,
                  message: "직급을 입력하세요.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="enDescription"
              label="소개"
              rules={[
                {
                  required: true,
                  message: "소개내용을 입력하세요.",
                },
              ]}
            >
              <TextArea />
            </Form.Item>
            <Form.Item
              name="enHistory"
              label="이력"
              rules={[
                {
                  required: true,
                  message: "이름을 입력하세요.",
                },
              ]}
            >
              <TextArea />
            </Form.Item>
          </Card>

          <Divider />
          <Form.Item name="categoryIds" label="담당 카테고리">
            <Select
              mode="multiple"
              options={categories.map((c) => ({
                label: c.categoryKo,
                value: c.id,
              }))}
            />
          </Form.Item>

          <Form.Item
            name="artwork"
            label="프로필 이미지"
            rules={[
              {
                validator(rule, value, callback) {
                  if (artwork.length > 0) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject();
                  }
                },
                message: "참여의료진 프로필등록은 필수입니다.",
              },
            ]}
          >
            <Upload
              fileList={artwork}
              name="artwork"
              multiple={false}
              maxCount={1}
              listType="picture"
              className="upload-list-inline"
              showUploadList={{ showPreviewIcon: true }}
              beforeUpload={(file: RcFile) => {
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                  message.error("Image must smaller than 2MB!");
                }
                return isLt2M;
              }}
              customRequest={({ file, onSuccess }) => {
                const res = "Ok";
                onSuccess!(res);
              }}
              accept=".jpg, .jpeg, .png, .webp"
              onChange={onChangeArtWork}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>

          <Button block htmlType="submit" type="primary">
            참여의료진 수정
          </Button>
        </Form>
      </HContent>
    </>
  );
}
