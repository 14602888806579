import { Header } from "antd/es/layout/layout";
import React, { useState } from "react";
import Title from "antd/es/typography/Title";
import { Breadcrumb } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { LPPUrls } from "../../../LPPUrls";
import HomeNoticePopupForm from "../../../components/notice/HomeNoticePopupForm";
import { PopupFormResult } from "../../../types/homeNoticePopupTypes";
import { LPPAXIOS } from "../../../framework/api/core.api";

export default function HomeNoticePopupCreate() {
  const navigate = useNavigate();

  return (
    <>
      <Header className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm bg-white">
        <Title level={4}>홈 공지 팝업 생성</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.HomeNoticePopup.Main.url()}>
              홈 공지 팝업 조회
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>홈 공지 팝업 생성</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HomeNoticePopupForm
        isCreateForm={true}
        onSubmit={async (popupFormResult: PopupFormResult) => {
          const apiResult = await LPPAXIOS.post('/admin/home-notice', popupFormResult)
          navigate(LPPUrls.Admin.HomeNoticePopup.Main.url(), {replace: true})
        }}
      />
    </>
  );
}
