import {
  Button,
  Input,
  notification,
  Pagination,
  PaginationProps,
  Space,
  Table,
  Typography,
} from "antd";

import { Content } from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";
import axios from "axios";
import { useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Header from "../../../components/common/Header";

import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";

import { navFilter, urlFilter } from "../../../utils/paramsFilter";
import { TableCategory } from "./type";

const { Title } = Typography;

export default function TablePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const keyword = params.get("keyword");
  const order = params.get("order");
  const sort = params.get("sort");
  const type = params.get("type");

  const [data, setData] = useState<[]>([]);
  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const TableColumn: ColumnsType<TableCategory> = [
    {
      title: "카테고리",
      dataIndex: "categoryKo",
      sorter: true,
      defaultSortOrder:
        sort === "categoryKo" ? (order === "ASC" ? "ascend" : "descend") : null,
    },

    {
      title: "사운드 수",
      dataIndex: "trackCount",
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "trackCount" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "",
      dataIndex: "operation",
      width: "20%",
      render: (_, record) => (
        <Space>
          <Button
            onClick={() =>
              navigate(LPPUrls.Admin.Category.Update.url(record.id))
            }
          >
            수정
          </Button>
          <Button
            onClick={() =>
              navigate(LPPUrls.Admin.Category.CategorySounds.url(record.id))
            }
          >
            사운드 관리
          </Button>
        </Space>
      ),
    },
  ];

  const handleTableChange = (value: any, filter: any, sorter: any) => {
    let order =
      sorter.order === null ? null : sorter.order === "ascend" ? "ASC" : "DESC";
    let sort = sorter && sorter.field ? sorter.field : null;

    let type =
      Array.isArray(filter["type"]) && filter["type"].length
        ? filter["type"][0]
        : null;

    handleChangeSort(sort, order, type);
  };

  const handleChangeSort = (
    sort: null | string,
    order: null | string,
    type: null | string
  ) => {
    navigate(
      LPPUrls.Admin.Category.Main.url(
        currentPage,
        navFilter({ keyword: keyword, sort: sort, order: order, type: type })
      )
    );
    setCurrentPage(currentPage);
  };

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      LPPUrls.Admin.Category.Main.url(
        page,
        navFilter({ keyword: keyword, sort: sort, order: order, type: type })
      )
    );
    setCurrentPage(page);
  };

  useLayoutEffect(() => {
    setLoading(true);
    LPPAXIOS.get(
      urlFilter({
        url: `/admin/category`,
        page: currentPage,
        perPage: 25,
        keyword: keyword,
        sort: sort,
        order: order,
        type: type,
      })
    )
      .then((res) => {
        setData(res.data.list);
        setTotal(res.data.list.length);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: `Notification ${error.code}`,
            description: `${error.message}`,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [api, currentPage, order, sort, type, keyword]);
  return (
    <>
      {contextHolder}
      <Header className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm">
        <Title level={4}>카테고리 조회</Title>
      </Header>
      <Content className="m-2 bg-white">
        <Table
          rowClassName={() => "cursor-pointer"}
          loading={loading}
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={data}
          columns={TableColumn}
          onChange={(val: any, filter: any, sorter: any, extra: any) => {
            handleTableChange(val, filter, sorter);
          }}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Space>
                <Title level={4}>카테고리 리스트</Title>
              </Space>
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
        />
      </Content>
    </>
  );
}
