export interface ClinicalTrialTrackTableInterface {
  id: number;
  titleKo: string;
  likeCount: number;
  createdAt: string;
  category: ClinicalTrialTrackCategoryEnum;
  writer: string;
  status: string;
}

export enum ClinicalTrialTrackCategoryEnum {
  STRESS = "stress",
  APPETITE = "appetite",
  SLEEP = "sleep",
}

export type ClinicalTrialTypeFormResult = {
  writer: string; // admin id
  artwork: string;
  thumbnail: string;
  url: string;
  category: ClinicalTrialTrackCategoryEnum;
  duration: number;
  tagKo: string;
  tagEn: string;
  titleKo: string;
  titleEn: string;
  descriptionKo: string;
  descriptionEn: string;
};

export type ClinicalTrialTrackType = {
  writer: string;
  category: ClinicalTrialTrackCategoryEnum;
  artwork: string;
  thumbnail: string;
  url: string;
  titleKo: string;
  titleEn: string;
  tagKo: string;
  tagEn: string;
  descriptionKo: string;
  descriptionEn: string;
  duration: number;
  status: string;
  createdAt: string;
  updatedAt: string;
}


export enum StatusEnum {
  Active = 'active',
  Inactive = 'inactive',
  Deleted = 'deleted',
}
