import { Header } from "antd/es/layout/layout";
import React, { useState } from "react";
import Title from "antd/es/typography/Title";
import { Breadcrumb, notification } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LPPUrls } from "../../../LPPUrls";
import HomeNoticePopupForm from "../../../components/notice/HomeNoticePopupForm";
import { PopupFormResult } from "../../../types/homeNoticePopupTypes";
import { LPPAXIOS } from "../../../framework/api/core.api";
import useNotification from "antd/es/notification/useNotification";

export default function HomeNoticePopupUpdate() {
  const { id } = useParams();
  const navigate = useNavigate();
  return (
    <>
      <Header className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm bg-white">
        <Title level={4}>홈 공지 팝업 수정</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.HomeNoticePopup.Main.url()}>
              홈 공지 팝업 조회
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>홈 공지 팝업 수정</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HomeNoticePopupForm
        isCreateForm={false}
        onSubmit={async (popupFormResult: PopupFormResult) => {
          const apiResult = await LPPAXIOS.put(`/admin/home-notice/${id}`, popupFormResult)
          navigate(LPPUrls.Admin.HomeNoticePopup.Main.url(), {replace: true})
        }}
      />
    </>
  );
}
