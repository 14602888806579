export function findCategoryS3Update(value: number | undefined) {
  switch (value) {
    case 1:
      return "minute_miracle";
    case 2:
      return "sleep";
    case 3:
      return "diet";
    case 4:
      return "concentration";
    case 5:
      return "stress";
    case 6:
      return "experience";
    case 7:
      return null;
    default:
      // Optionally handle any other cases or provide a default
      return null;
  }
}
