import Button from "antd/lib/button";
import Input from "antd/lib/input";
import notification from "antd/lib/notification";
import Pagination, { PaginationProps } from "antd/lib/pagination";
import Space from "antd/lib/space";
import Table from "antd/lib/table";
import Typography from "antd/lib/typography";

import { Content } from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";
import axios from "axios";
import { Key, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../../components/common/Header";
import { LPPAXIOS } from "../../../framework/api/core.api";

import { LPPUrls } from "../../../LPPUrls";

import { timeConverter } from "../../../utils/dateTimeConveter";
import { preventSpaceBar } from "../../../utils/inputOnlyNumber";
import {
  filterNonEmptyProperties,
  paramsFilter,
} from "../../../utils/paramsFilter";
import { TABLERESPONSE, TRACKTABLE } from "./type";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { Popconfirm } from "antd";
import { CategoryFromApiType } from "../../../types/CategoryFromApiType";

const { Title } = Typography;

export default function TablePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const keyword = params.get("keyword");
  const order = params.get("order");
  const sort = params.get("sort");

  const categoryId = params.get("categoryId");

  const [data, setData] = useState<TRACKTABLE[]>([]);
  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [category, setCategory] = useState<
    {
      text: CategoryFromApiType["categoryKo"];
      value: CategoryFromApiType["id"];
    }[]
  >([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const TableColumn: ColumnsType<TRACKTABLE> = [
    {
      title: "타이틀",
      dataIndex: "titleKo",
      render: (_, record) => record.titleKo,
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "titleKo" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "카테고리",
      dataIndex: "categoryKo",
      key: "categoryId",
      render: (_, record) => record.categoryKo,
      ellipsis: true,
      filters: category,
      filterMultiple: false,
      defaultFilteredValue: categoryId === null ? [] : [categoryId],
    },

    {
      title: "좋아요수",
      dataIndex: "likeCount",
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "likeCount" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "업로드일",
      dataIndex: "createdAt",
      render: (_, record) => timeConverter(record.createdAt).slice(0, 10),
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "createdAt" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "음원 파일",
      dataIndex: "musicFile",
      render: (_, record) => (
        <>
          <Space>
            {record.url ? (
              <Button
                onClick={(e) => {
                  console.log(record.url);
                  e.stopPropagation();
                  window.open(
                    `${process.env.REACT_APP_IMG_ENDPOINT}/${record.url}`,
                    "_blank"
                  );
                }}
              >
                원본
              </Button>
            ) : (
              ""
            )}
            {record.trialMusicUrl ? (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(
                    `${process.env.REACT_APP_IMG_ENDPOINT}/${record.trialMusicUrl}`,
                    "_blank"
                  );
                }}
              >
                체험
              </Button>
            ) : (
              ""
            )}
          </Space>
        </>
      ),
    },
    {
      title: (
        <Popconfirm
          disabled={selectedRowKeys.length === 0}
          title="사운드 삭제"
          description="사운드을 삭제하시겠습니까?"
          onConfirm={(e: any) => {
            handleDeleteAll();
          }}
          onCancel={(e: any) => {}}
          okText="Yes"
          cancelText="No"
        >
          <Button danger disabled={selectedRowKeys.length === 0}>
            삭제
          </Button>
        </Popconfirm>
      ),
      render: (_, record) => (
        <>
          <Space>
            <Button
              onClick={(e) => {
                e.stopPropagation(); // Stop event propagation
                // Handle edit logic here
                handleEdit(record);
              }}
            >
              수정
            </Button>
            <Popconfirm
              title="사운드 삭제"
              description="사운드을 삭제하시겠습니까?"
              onConfirm={(e: any) => {
                e.stopPropagation(); // Stop event propagation
                handleDelete(record);
              }}
              onCancel={(e: any) => {
                e.stopPropagation(); // Stop event propagation
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                danger
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                삭제
              </Button>
            </Popconfirm>
          </Space>
        </>
      ),
    },
  ];

  const handleExcel = async () => {
    const workbook = new Workbook();
    const soundSheet = workbook.addWorksheet("사운드 리스트 조회");

    soundSheet.columns = [
      { header: "Id", key: "id", width: 10 },
      { header: "타이틀", key: "titleKo", width: 32 },
      { header: "카테고리", key: "categoryKo", width: 32 },
      { header: "좋아요 수", key: "likeCount", width: 20 },
      { header: "업로드일", key: "createdAt", width: 32 },
    ];
    data.forEach((item, idx) => {
      soundSheet.addRow({
        id: item.id,
        titleKo: item.titleKo,
        categoryKo: item.categoryKo,
        likeCount: item.likeCount,
        createdAt: timeConverter(item.createdAt).slice(0, 10),
      });
    });

    // 다운로드
    const mimeType = {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    };
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], mimeType);
    saveAs(blob, `사운드 리스트 ${currentPage}페이지.xlsx`);
  };

  const handleEdit = (record: TRACKTABLE) => {
    // Handle edit action here
    navigate(LPPUrls.Admin.Sounds.Update.url(record.id));
  };

  /** 개별 삭제 */
  const handleDelete = (record: TRACKTABLE) => {
    // Handle delete action here
    LPPAXIOS.post(`/admin/track/delete/multiple`, {
      ids: [record.id],
    })
      .then((resolve) => {
        api.success({
          message: "사운드가 삭제되었습니다.",
        });
        setData((prev) => prev.filter((item) => item.id !== record.id));
      })
      .catch((error) => {
        api.error({
          message: "사운드 삭제 실패",
        });
      });
  };
  const handleDeleteAll = () => {
    // Handle delete action here
    LPPAXIOS.post(`/admin/track/delete/multiple`, {
      ids: selectedRowKeys,
    })
      .then((resolve) => {
        api.success({
          message: "사운드가 삭제되었습니다.",
        });
        window.location.reload();
      })
      .catch((error) => {
        api.error({
          message: "사운드 삭제 실패",
        });
      });
  };

  const onClickCreateSound = () => {
    navigate(LPPUrls.Admin.Sounds.Create.pathName);
  };

  const handleTableChange = (value: any, filter: any, sorter: any) => {
    let order =
      sorter.order === null ? null : sorter.order === "ascend" ? "ASC" : "DESC";
    let sort = sorter && sorter.field ? sorter.field : null;
    let result = filterNonEmptyProperties(filter);

    handleChangeSort(sort, order, result);
  };

  const handleChangeSort = (
    sort: null | string,
    order: null | string,

    result: { [key: string]: string | null }
  ) => {
    navigate(
      LPPUrls.Admin.Sounds.Main.url(
        currentPage,
        paramsFilter({ keyword: keyword, sort: sort, order: order, ...result })
      )
    );
    setCurrentPage(currentPage);
  };

  const handleOnSearchFilter = (value: string) => {
    navigate(
      LPPUrls.Admin.Sounds.Main.url(
        1,
        paramsFilter({
          keyword: value,
          sort: sort,
          order: order,
          categoryId: categoryId,
        })
      )
    );
    setCurrentPage(1);
  };
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      LPPUrls.Admin.Sounds.Main.url(
        page,
        paramsFilter({
          keyword: keyword,
          sort: sort,
          order: order,
          categoryId: categoryId,
        })
      )
    );
    setCurrentPage(page);
  };

  useLayoutEffect(() => {
    setLoading(true);
    LPPAXIOS.get<TABLERESPONSE>(
      `/admin/track/search?` +
        paramsFilter({
          page: currentPage,
          perPage: 25,
          keyword: keyword,
          sort: sort,
          order: order,
          categoryId: categoryId,
        })
    )
      .then((res) => {
        setData(res.data.list);
        setTotal(res.data.total);
        LPPAXIOS.get(`/admin/category`)
          .then((res) => {
            const categoryFromApi = res.data.list.map(
              (category: CategoryFromApiType) => ({
                text: category.categoryKo,
                value: category.id,
              })
            );

            setCategory([
              ...categoryFromApi,
              { text: "카테고리 없음", value: "none" },
            ]);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error: { code: any; message: any }) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: `Notification ${error.code}`,
            description: `${error.message}`,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [api, currentPage, order, sort, keyword, categoryId]);

  return (
    <>
      {contextHolder}
      <Header className="flex items-center justify-between py-1 my-2 mb-10 drop-shadow-sm">
        <Title level={4}>사운드</Title>
        <Button onClick={onClickCreateSound}>사운드 생성</Button>
      </Header>
      <Content className="m-2 bg-white">
        <Table
          rowClassName={() => "cursor-pointer"}
          loading={loading}
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={data}
          columns={TableColumn}
          onChange={(val: any, filter: any, sorter: any, extra: any) => {
            handleTableChange(val, filter, sorter);
          }}
          onRow={(record, _) => {
            return {
              onClick: () => {
                navigate(LPPUrls.Admin.Sounds.Detail.url(record.id));
              }, // click row
            };
          }}
          rowSelection={{
            selectedRowKeys,
            onChange: (selectedKeys) => {
              setSelectedRowKeys(selectedKeys);
            },
          }}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Space>
                <Title level={4}>사운드 리스트</Title>
                <Button onClick={handleExcel}>엑셀 다운로드</Button>
              </Space>

              <Input.Search
                defaultValue={keyword ?? ""}
                style={{ width: 300 }}
                placeholder="사운드 타이틀을 검색하세요."
                onSearch={handleOnSearchFilter}
                onInput={preventSpaceBar}
              />
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
        />
      </Content>
    </>
  );
}
