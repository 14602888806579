import { Navigate, Route, Routes } from "react-router-dom";
import ClinicalTrialTrackTable from "./Table";
import { LPPUrls } from "../../../../LPPUrls";
import ClinicalTrialTrackCreate from "./Create";
import ClinicalTrialTrackUpdate from "./Update";
import ClinicalTrialTrackDetail from "./Detail";

export default function ClinicalTrialTrackRoot() {
  return (
    <Routes>
      <Route path="/" element={<ClinicalTrialTrackTable />} />
      <Route
        path={LPPUrls.Admin.ClinicalTrialTrack.Create.pathName}
        element={<ClinicalTrialTrackCreate />}
      />
      <Route
        path={LPPUrls.Admin.ClinicalTrialTrack.Update.pathName}
        element={<ClinicalTrialTrackUpdate />}
      />
      <Route
        path={LPPUrls.Admin.ClinicalTrialTrack.Detail.pathName}
        element={<ClinicalTrialTrackDetail />}
      />
      <Route path="/*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
}
