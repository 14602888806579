import React from "react";
import ClinicalTrialTrackForm from "../../../../components/clinicalTrial/ClinicalTrialTrackForm";
import { ClinicalTrialTypeFormResult } from "./type";
import { LPPAXIOS } from "../../../../framework/api/core.api";
import { LPPUrls } from "../../../../LPPUrls";
import { useNavigate } from "react-router-dom";

export default function ClinicalTrialTrackCreate() {
  const navigate = useNavigate();

  return (
    <>
      <ClinicalTrialTrackForm
        isCreateForm={true}
        onSubmit={async (formResult: ClinicalTrialTypeFormResult) => {
          const apiResult = await LPPAXIOS.post(
            `/admin/clinical-trial/track`,
            formResult
          );
          return Boolean(apiResult.data?.success)
        }}
      />
    </>
  );
}
