import { Navigate, Route, Routes } from "react-router-dom";
import { LPPUrls } from "../../../LPPUrls";
import HomeNoticePopupTable from "./Table";
import HomeNoticePopupCreate from "./Create";
import HomeNoticePopupUpdate from "./Update";

export default function HomeNoticePopupRoot() {
  return (
    <Routes>
      <Route path="/" element={<HomeNoticePopupTable />} />
      <Route
        path={LPPUrls.Admin.HomeNoticePopup.Create.pathName}
        element={<HomeNoticePopupCreate />}
      />
        <Route
        path={LPPUrls.Admin.HomeNoticePopup.Update.pathName}
        element={<HomeNoticePopupUpdate />}
      />
      <Route path="/*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
}
