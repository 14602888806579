import { UploadFile } from "antd";

export enum PopupTypeEnum {
  IMAGE = "image",
  TEXT = "text",
}

export enum PopupTargetEnum {
  ALL_USERS = "All Users", // 모든 유저
  SIGNUP_USERS = "Signed-up Users", // 가입한 유저
  SUBSCRIBED_USERS = "Subscribed Users", // 구독 중인 유저
  UNSUBSCRIBED_USERS = "Unsubscribed Users", // 구독하지 않은 유저
  EXPLORING_USERS = "Exploring Users", // 탐색 중인 유저
}

export enum PopupLanguagesEnum {
  KO = "ko",
  EN = "en",
}

export enum PopupPlatformEnum {
  PC = "pc",
  MOBILE = "mobile",
}

export type ImagesStateType = {
  [lang in PopupLanguagesEnum]: {
    [platform in PopupPlatformEnum]: UploadFile[];
  };
};

export type PopupFormResult = {
  popupType: PopupTypeEnum;
  exposeTarget: PopupTargetEnum;
  startIsoDate: string;
  endIsoDate: string;
  writer: string;
  koTitle: string;
  koContent: string;
  enTitle: string;
  enContent: string;
  koPcImage: string;
  koMobileImage: string;
  enPcImage: string;
  enMobileImage: string;
};

export type HomeNoticeTable = {
  id: string;
  popupType: PopupTypeEnum;
  exposeTarget: PopupTargetEnum;
  isExpose: 0 | 1; // 현재 팝업이 노출중인지
  startIsoDate: string; 
  endIsoDate: string; 
  writer: string;
  createdAt: string;
  status: string;
  koTitle: string,
  koMobileImage: string
}

export enum StatusEnum {
  Active = 'active',
  Inactive = 'inactive',
  Deleted = 'deleted',
}
