import { useLocation, useNavigate } from "react-router-dom";
import { ClinicalTrialUserTableInterface } from "./type";
import { useLayoutEffect, useState } from "react";
import {
  Button,
  notification,
  Pagination,
  PaginationProps,
  Space,
  Typography,
} from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { timeConverter } from "../../../../utils/dateTimeConveter";
import { Content, Header } from "antd/es/layout/layout";
import { LPPUrls } from "../../../../LPPUrls";
import { paramsFilter, urlFilter } from "../../../../utils/paramsFilter";
import { LPPAXIOS } from "../../../../framework/api/core.api";
const { Title } = Typography;

export default function ClinicalTrialUserTable() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const order = params.get("order");
  const sort = params.get("sort");
  const [data, setData] = useState<ClinicalTrialUserTableInterface[]>([]);
  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const TableColumn: ColumnsType<ClinicalTrialUserTableInterface> = [
    {
      title: "번호",
      dataIndex: "userNumber",
      render: (_, record) => record.userNumber,
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "userNumber" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "코드",
      dataIndex: "code",
      render: (_, record) => record.code,
      ellipsis: true,
    },
    {
      title: "상태",
      dataIndex: "status",
      key: "status",
      render: (_, record) => record.status,
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "status" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "생성일",
      dataIndex: "createdAt",
      render: (_, record) => timeConverter(record.createdAt).slice(0, 10),
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "createdAt" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
  ];

    const handleChangeSort = (
      sort: null | string,
      order: null | string,
  
      result: { [key: string]: string | null }
    ) => {
      navigate(
        LPPUrls.Admin.ClinicalTrialUsers.Main.url(
          currentPage,
          paramsFilter({ sort: sort, order: order, ...result })
        )
      );
      setCurrentPage(currentPage);
    };

  const handleTableChange = (value: any, filter: any, sorter: any) => {
    let order =
      sorter.order === null ? null : sorter.order === "ascend" ? "ASC" : "DESC";
    let sort = sorter && sorter.field ? sorter.field : null;
    let type =
      Array.isArray(filter["type"]) && filter["type"].length
        ? filter["type"][0]
        : null;

    handleChangeSort(sort, order, type);
  };
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      LPPUrls.Admin.ClinicalTrialUsers.Main.url(
        page,
        paramsFilter({
          sort: sort,
          order: order,
        })
      )
    );
    setCurrentPage(page);
  };

  useLayoutEffect(() => {
      setLoading(true);
      const getList = async () => {
        try {
          const res = await LPPAXIOS.get(
            urlFilter({
              url: `/admin/clinical-trial/users`,
              page: currentPage,
              perPage: 25,
              sort: sort ?? 'userNumber',
              order: order ?? 'ASC',
            })
          );
          setData(res.data.list);
          setTotal(res.data.total);
        } catch (error) {
          api.error({
            message: `An unexpected error occurred`,
          });
        } finally {
          setLoading(false);
        }
      };
      getList();
    }, [api, currentPage, order, sort]);
  return (
    <>
      {contextHolder}
      <Header className="flex items-center justify-between py-1 my-2 mb-10 drop-shadow-sm bg-white">
        <Title level={4}>유저 코드</Title>
      </Header>

      <Content className="m-2 bg-white">
        <Table
          rowClassName={() => "cursor-pointer"}
          loading={loading}
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={data}
          columns={TableColumn}
          onChange={(val: any, filter: any, sorter: any, extra: any) => {
            handleTableChange(val, filter, sorter);
          }}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Space>
                <Title level={4}>유저 코드 리스트</Title>
              </Space>
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
        ></Table>
      </Content>
    </>
  );
}
