import React from "react";
import ClinicalTrialTrackForm from "../../../../components/clinicalTrial/ClinicalTrialTrackForm";
import { ClinicalTrialTypeFormResult } from "./type";
import { LPPAXIOS } from "../../../../framework/api/core.api";
import { useParams } from "react-router-dom";

export default function ClinicalTrialTrackUpdate() {
    const { id } = useParams();
  
  return (
    <>
      <ClinicalTrialTrackForm
        isCreateForm={false}
        onSubmit={async (formResult: ClinicalTrialTypeFormResult) => {
          const apiResult = await LPPAXIOS.put(
            `/admin/clinical-trial/track/${id}`,
            formResult
          );
          return Boolean(apiResult.data?.success)
        }}
      ></ClinicalTrialTrackForm>
    </>
  );
}
