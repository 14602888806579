export enum LoginType {
  GOOGLE = "google",
  FACEBOOK = "facebook",
  APPLE = "apple",
  NAVER = "naver",
  KAKAO = "kakao",
  EMAIL = "email",
}

export interface TABLERESPONSE {
  list: USERTABLE[];
  total: number;
}

export interface USERTABLE {
  id: number;
  nickName: string;
  email: string;
  createdAt: string;
  currentSubscription: Subscription;
  type: string;
}

export interface USERDETAILTYPE {
  id: number;
  nickName: string;
  email: string;
  createdAt: string;
  userStatus: string;
  subscriptionHistories: {
    list: Subscription[];
    total: number;
  };
}

export interface Subscription {
  id: number;
  startAt: string;
  endAt: string;
  status: string;
  isCoupon: boolean;
}

export enum SubscriptionType {
  SUBSCRIPTION = "subscription",
  COUPON_SUBSCRIPTION = "couponSubscription",
  NON_SUBSCRIPTION = "nonSubscription",
}
