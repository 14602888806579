import {
  Button,
  DatePicker,
  Divider,
  Form,
  Image,
  notification,
  Pagination,
  PaginationProps,
  Space,
  Typography,
} from "antd";
import { Content, Header } from "antd/es/layout/layout";
import React, {
  Key,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LPPUrls } from "../../../LPPUrls";
import Table, { ColumnsType } from "antd/es/table";
import {
  HomeNoticeTable,
  PopupTypeEnum,
  StatusEnum,
} from "../../../types/homeNoticePopupTypes";
import { LPPAXIOS } from "../../../framework/api/core.api";
import {
  navDateFilter,
  navFilter,
  urlFilter,
} from "../../../utils/paramsFilter";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export default function HomeNoticePopupTable() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const keyword = params.get("keyword");
  const order = params.get("order");
  const sort = params.get("sort");
  const type = params.get("type");
  const [startKstDate, setStartKstDate] = useState(params.get("startKstDate"));
  const [endKstDate, setEndKstDate] = useState(params.get("endKstDate"));
  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<HomeNoticeTable[]>([]);
  const [total, setTotal] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const [searchForm] = Form.useForm();

  useLayoutEffect(() => {
    if (startKstDate && endKstDate) {
      searchForm.setFieldsValue({
        startKstDate: dayjs(startKstDate).startOf("day"),
        endKstDate: dayjs(endKstDate).startOf("day"),
      });
    }
  }, []);

  const { Title } = Typography;

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      LPPUrls.Admin.HomeNoticePopup.Main.url(
        page,
        navDateFilter({
          keyword: keyword,
          sort: sort,
          order: order,
          type: type,
          startKstDate: startKstDate,
          endKstDate: endKstDate,
        })
      )
    );
    setCurrentPage(page);
  };

  const TableColumn: ColumnsType<HomeNoticeTable> = [
    {
      title: "팝업 타입",
      dataIndex: "popupType",

      render: (_, record) =>  {
        if(record.popupType === PopupTypeEnum.IMAGE) {
          return (<Image src={`${process.env.REACT_APP_IMG_ENDPOINT}/${record.koMobileImage}`} width={100} height={100}></Image>)
        } else if (record.popupType === PopupTypeEnum.TEXT) {
          return record.koTitle
        }
      },
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "popupType" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "노출 타겟",
      dataIndex: "exposeTarget",
      render: (_, record) => record.exposeTarget,
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "exposeTarget"
          ? order === "ASC"
            ? "ascend"
            : "descend"
          : null,
    },
    {
      title: "노출중 여부",
      dataIndex: "isExpose",
      render: (_, record) => {
        return (
          <div className="flex justify-center items-center">
            <div className="flex-1 justify-center items-center flex">
              <div
                style={{
                  width: 15,
                  height: 15,
                  borderRadius: "50%",
                  backgroundColor: record.isExpose === 1 ? "green" : "gray",
                  display: "inline-block",
                }}
              />
            </div>
            <div className="flex-1 ">
              {record.isExpose === 1 ? "진행중" : "중지"}
            </div>
          </div>
        );
      },
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "isExpose" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "팝업 상태",
      dataIndex: "status",
      render: (_, record) => {
        if (record.status === StatusEnum.Active) return "활성";
        if (record.status === StatusEnum.Inactive) return "비활성";
        if (record.status === StatusEnum.Deleted) return "삭제";
        return "상태 알 수 없음";
      },
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "status" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "KST 노출 시작일",
      dataIndex: "startIsoDate",
      render: (_, record) =>
        dayjs
          .utc(record.startIsoDate)
          .tz("Asia/Seoul")
          .format("YYYY-MM-DD HH:mm:ss"),
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "startIsoDate"
          ? order === "ASC"
            ? "ascend"
            : "descend"
          : null,
    },
    {
      title: "KST 노출 종료일",
      dataIndex: "endIsoDate",
      render: (_, record) =>
        dayjs
          .utc(record.endIsoDate)
          .tz("Asia/Seoul")
          .format("YYYY-MM-DD HH:mm:ss"),
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "endIsoDate" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "KST 작성일",
      dataIndex: "createdAt",
      render: (_, record) =>
        dayjs
          .utc(record.createdAt)
          .tz("Asia/Seoul")
          .format("YYYY-MM-DD HH:mm:ss"),
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "createdAt" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "작성자",
      dataIndex: "writer",
      render: (_, record) => record.writer,
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "writer" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
  ];
  const handleTableChange = (value: any, filter: any, sorter: any) => {
    let order =
      sorter.order === null ? null : sorter.order === "ascend" ? "ASC" : "DESC";
    let sort = sorter && sorter.field ? sorter.field : null;

    let type =
      Array.isArray(filter["type"]) && filter["type"].length
        ? filter["type"][0]
        : null;

    handleChangeSort(sort, order, type);
  };

  const handleChangeSort = (
    sort: null | string,
    order: null | string,
    type: null | string
  ) => {
    navigate(
      LPPUrls.Admin.HomeNoticePopup.Main.url(
        currentPage,
        navFilter({ keyword: keyword, sort: sort, order: order, type: type })
      )
    );
    setCurrentPage(currentPage);
  };

  const onPatchStatus = async (status: StatusEnum) => {
    if (selectedRowKeys.length) {
      try {
        setLoading(true);
        let storageUserInfo = localStorage.getItem("app.soundpill.admin.info");
        if (!storageUserInfo) throw new Error("not found storageUserInfo");
        const parseStorageUserInfo = JSON.parse(storageUserInfo);
        for (const id of selectedRowKeys) {
          const result = await LPPAXIOS.patch(
            `/admin/home-notice/status/${id}`,
            { status, writer: parseStorageUserInfo.id }
          );
          if (!result.data?.success) throw new Error(`api failed ${id}`);
        }
      } catch (error) {
        api.error({ message: "상태 변경 실패" });
      } finally {
        setLoading(false);
        window.location.reload();
      }
    } else {
      api.error({ message: "상태 변경 할 팝업이 없습니다." });
    }
  };

  useLayoutEffect(() => {
    setLoading(true);
    let url = urlFilter({
      url: `/admin/home-notice`,
      page: currentPage,
      perPage: 25,
      keyword: keyword,
      sort: sort ?? "createdAt",
      order: order ?? "desc",
      type: type,
    });
    if (startKstDate && endKstDate) {
      url += navDateFilter({ startKstDate, endKstDate });
    }
    LPPAXIOS.get(url).then((res) => {
      setData(res.data.list);
      setTotal(res.data.total);
    });
    setLoading(false);
  }, [currentPage, order, sort, type, keyword, startKstDate, endKstDate]);

  return (
    <>
      {contextHolder}
      <Header className="flex items-center justify-between py-1 my-2 mb-10 drop-shadow-sm bg-white">
        <Title level={4}>홈 공지 팝업</Title>
        <div className="flex flex-row justify-center items-center gap-10">
          <Form
            form={searchForm}
            onFinish={(searchForm) => {
              const { startKstDate, endKstDate } = searchForm;
              navigate(
                LPPUrls.Admin.HomeNoticePopup.Main.url(
                  1,
                  navDateFilter({
                    keyword: keyword,
                    sort: sort,
                    order: order,
                    type: type,
                    startKstDate: startKstDate.format("YYYY-MM-DD"),
                    endKstDate: endKstDate.format("YYYY-MM-DD"),
                  })
                )
              );
              setStartKstDate(startKstDate.format("YYYY-MM-DD"));
              setEndKstDate(endKstDate.format("YYYY-MM-DD"));
              
              setCurrentPage(1);
            }}
            className="flex flex-row gap-2 justify-center items-center"
          >
            <Form.Item
              name="startKstDate"
              label="노출 시작 시간"
              className="p-0 m-0"
              rules={[
                {
                  required: true,
                  message: "노출 시작 시간을 설정하세요.",
                },
              ]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              name="endKstDate"
              label="노출 종료 시간"
              className="p-0 m-0"
              rules={[
                {
                  required: true,
                  message: "노출 종료 시간을 설정하세요.",
                },
              ]}
            >
              <DatePicker />
            </Form.Item>
            <Button className="bg-purple-800 text-white" htmlType="submit">
              검색
            </Button>
            <Button onClick={() => {
                navigate(LPPUrls.Admin.HomeNoticePopup.Main.url(), {replace: true})
                window.location.reload();
              }}>
              초기화
            </Button>
          </Form>
          <Button
            onClick={() =>
              navigate(LPPUrls.Admin.HomeNoticePopup.Create.pathName)
            }
          >
            홈 공지 팝업 생성
          </Button>
        </div>
      </Header>
      <Content className="m-2 bg-white">
        <Table
          rowClassName={() => "cursor-pointer"}
          loading={loading}
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={data}
          columns={TableColumn}
          rowSelection={{
            selectedRowKeys,
            onChange: (selectedKeys) => {
              setSelectedRowKeys(selectedKeys);
            },
          }}
          onChange={(val: any, filter: any, sorter: any, extra: any) => {
            handleTableChange(val, filter, sorter);
          }}
          onRow={(record, _) => {
            return {
              onClick: () => {
                navigate(LPPUrls.Admin.HomeNoticePopup.Update.url(record.id));
              }, // click row
            };
          }}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Space className="flex  justify-start flex-1">
                <Title level={4}>공지사항 리스트</Title>
                <p className="p-0 m-0 ml-5 mb-[10px]">
                  노출 조건 (팝업 상태가 활성이며 KST 노출시간)
                </p>
              </Space>
              <Space className="mb-[10px] flex-col flex items-end">
                <div className="flex flex-row gap-2 mt-2">
                  <Button onClick={() => onPatchStatus(StatusEnum.Active)}>
                    활성
                  </Button>
                  <Button onClick={() => onPatchStatus(StatusEnum.Inactive)}>
                    비활성
                  </Button>
                </div>
              </Space>
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
        />
      </Content>
    </>
  );
}
